
#gallery-view .top-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-right: 8px;
}

#gallery-view .done-button {
    width: 36px;
    flex: 0 0 36px;
    max-width: 36px;
    min-width: unset;
}

#gallery-view .gallery-divider {
    margin: 20px 0;
}

#gallery-view .images-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -8px;
}

#gallery-view .images-wrapper .gallery-view-item {
    margin-right: 8px;
    margin-bottom: 8px;
    color: white;
    position: relative;
    width: calc(33.3% - 8px);
}

#gallery-view .images-wrapper .gallery-view-item > span {
    width: 100%;
    height: 100%;
}

#gallery-view .images-wrapper .gallery-view-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#gallery-view .images-wrapper .gallery-view-item .overlay {
    position: absolute;
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#gallery-view .images-wrapper .gallery-view-item .overlay .overlay-icon {
    color: #fff;
    font-size: 100px;
    text-shadow: rgba(0, 0, 0, 0.17);
}

@media(max-width:768px){
    #gallery-view .images-wrapper .gallery-view-item{
        width: calc(50% - 8px);
        height: auto!important;
        top: 0!important;
        padding:0;
        margin:4px;
    }
    #gallery-view .images-wrapper .gallery-view-item .overlay .overlay-icon{
        font-size:50px;
    }
    #gallery-view .top-section button{
        font-size:10px!important;
    }
    #gallery-view .done-button {
        width: 24px;
        flex: 0 0 24px;
        max-width: 24px;
        padding:0;
        margin:4px;
    }
    #gallery-view .gallery-divider{
        margin:10px 0;
    }
    #gallery-view .images-wrapper{
        margin-right:0;
    }
}
