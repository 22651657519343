header#app-header {
    width: 100%;
}

header#app-header:not(.__has-page-header) {
    margin-bottom: 28px;
}

header#app-header.__has-page-header {
    margin-bottom: 14px;
}

header#app-header .page-header-wrapper {
    padding: 16px 0;
}

header#app-header #page-header .page-header-button-text {
    font-size: 20px;
}

header#app-header .header-content {
    height: 88px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px rgba(63, 82, 109, 0.28);
}

header#app-header .header-content .header-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

header#app-header .header-content .action-icons {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    flex-wrap: nowrap;
    margin-right: 64px;
    position: relative;
    height: 48px;
}

header#app-header .header-content .action-icons img {
    object-fit: contain;
}

header#app-header .header-content .action-icons.__show .action-item-wrapper:nth-child(6) { transition-delay: 100; transform: translateX(0); -webkit-transform: translateX(0); -moz-transform: translateX(0); -ms-transform: translateX(0); -o-transform: translateX(0); }
header#app-header .header-content .action-icons.__show .action-item-wrapper:nth-child(5) { transition-delay: 200; transform: translateX(-48px); -webkit-transform: translateX(-48px); -moz-transform: translateX(-48px); -ms-transform: translateX(-48px); -o-transform: translateX(-48px); }
header#app-header .header-content .action-icons.__show .action-item-wrapper:nth-child(4) { transition-delay: 300; transform: translateX(-96px); -webkit-transform: translateX(-96px); -moz-transform: translateX(-96px); -ms-transform: translateX(-96px); -o-transform: translateX(-96px); }
header#app-header .header-content .action-icons.__show .action-item-wrapper:nth-child(3) { transition-delay: 400; transform: translateX(-144px); -webkit-transform: translateX(-144px); -moz-transform: translateX(-144px); -ms-transform: translateX(-144px); -o-transform: translateX(-144px); }
header#app-header .header-content .action-icons.__show .action-item-wrapper:nth-child(2) { transition-delay: 500; transform: translateX(-192px); -webkit-transform: translateX(-192px); -moz-transform: translateX(-192px); -ms-transform: translateX(-192px); -o-transform: translateX(-192px); }
header#app-header .header-content .action-icons.__show .action-item-wrapper:nth-child(1) { transition-delay: 600; transform: translateX(-240px); -webkit-transform: translateX(-240px); -moz-transform: translateX(-240px); -ms-transform: translateX(-240px); -o-transform: translateX(-240px); }

header#app-header .header-content .action-icons .action-item-wrapper:nth-child(6) { transition-delay: 600; }
header#app-header .header-content .action-icons .action-item-wrapper:nth-child(5) { transition-delay: 500; }
header#app-header .header-content .action-icons .action-item-wrapper:nth-child(4) { transition-delay: 400; }
header#app-header .header-content .action-icons .action-item-wrapper:nth-child(3) { transition-delay: 300; }
header#app-header .header-content .action-icons .action-item-wrapper:nth-child(2) { transition-delay: 200; }
header#app-header .header-content .action-icons .action-item-wrapper:nth-child(1) { transition-delay: 100; }


header#app-header .header-content .action-icons .action-item-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    transform: translateX(0);
    pointer-events: none;
    transition: 300ms ease;
    user-select: none;
}

header#app-header .header-content .action-icons.__show .action-item-wrapper {
    opacity: 1;
    pointer-events: unset;
    user-select: unset;
}


header#app-header .header-content .action-icons .action-item-wrapper.notifications-action svg {
    color: #ffc009;
    font-size: 28px;
}

header#app-header .header-content .action-icons .action-item-wrapper.notifications-action .MuiBadge-anchorOriginTopRightRectangle {
    top: 7px;
    right: 4px;
    height: 14px;
    min-width: 14px;
}

header#app-header .header-content .profile-button {
    width: 28px;
    height: 28px;
    min-width: 28px;
    max-width: 28px;
    border-radius: 50%;
    margin-right: 18px;
    padding-left: 14px;
    padding-right: 14px;
}

header#app-header .header-content .profile-picture {
    width: 64px;
    height: 64px;
}

/* Responsive Designs */
@media screen and (min-width: 1025px) {
    header#app-header .header-content{
        background-color: unset!important;
    }
}

@media screen and (max-width: 1024px) {
    header#app-header {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 999;
    }

    header#app-header.__has-page-header .header-content {
        border-width: 0;
    }

    header#app-header .header-content {
        height: 64px;
        width: 100%;
        padding: 0 28px 0 28px;
        box-sizing: border-box;
    }

    header#app-header .header-content .header-actions .profile-button {
        background-color: #fff;
        background-image: unset!important;
    }

    header#app-header .header-content .profile-picture {
        width: 34px;
        height: 34px;
    }


    header#app-header.__has-page-header .page-header-wrapper {
        border-bottom: solid 1px rgba(63, 82, 109, 0.28);
    }

    header#app-header .page-header-wrapper {
        background-color: #fff;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 28px;
        padding-right: 28px;
        height: 58px;
    }

    header#app-header .page-header-wrapper #page-header {
        height: 100%;
    }
}

@media screen and (max-width: 767px) {
    header#app-header .header-content {
        padding: 0 16px;
    }

    header#app-header .page-header-wrapper {
        padding: 0 16px;
    }

    header#app-header .header-content .action-icons {
        margin-right: 0;
    }
}