#capture-photo-view {

}

#capture-photo-view .camera video {
  max-width: 100%;
}

#capture-photo-view .take-photo-button {
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  width: 280px;
  max-width: 100%;
  text-transform: unset;
  font-weight: 600;
}

#capture-photo-view #canvas{
  display: none;
}