.generic-card {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1)!important;
    padding: 20px;
    border-radius: 8px;
  }
  
  .generic-card .generic-card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  
  .generic-card .generic-card-subtitle {
    color: #3f526d;
    font-weight: 600;
    font-size: 18px;
  }
  
  .generic-card .card-divider {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: rgba(63, 82, 109, 0.12);
  }