#calendar-widget .sdp{
    width:unset;
    font-family:Open Sans,sans-serif;
    box-shadow:none;
    border:none;
    padding-right:0;
    padding-left:0;
}

#calendar-widget .sdp--header{
    border-bottom:1px solid rgba(63, 82, 109, 0.12);
    border-top:1px solid rgba(63, 82, 109, 0.12);
    padding:10px 0;
}

#calendar-widget.generic-card .card-divider{
    display:none;
}

#calendar-widget .sdp--header .sdp--square-btn{
    border:none;
    box-shadow:none;
    color:#3f526d;
    opacity:0.3;
}

#calendar-widget .sdp--header__main{
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3f526d;
}

#calendar-widget .sdp--grid>p.sdp--text{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #7a879b;
}

#calendar-widget .sdp--grid{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

#calendar-widget .sdp--grid:last-child .sdp--text {
    color: #3f526d;
    opacity:0.7;
}

#calendar-widget .sdp--grid:last-child .sdp--text__inactive {
    color: #3f526d;
    opacity:0.3;
}

#calendar-widget .sdp--grid:last-child .sdp--date-btn__selected{
    background-color:#7f6cfc;
    opacity:1;
    color:white;
}
