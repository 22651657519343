.shortcuts-container{
    display:flex;
    gap:24px;
}

.shortcuts-left-column,
.shortcuts-right-column{
    display:flex;
    flex-direction:column;
    flex:0 0 50%;
    width: 50%;
    gap:16px;
}

.shortcuts-left-column .generic-card,
.shortcuts-right-column .generic-card{
    width:100%!important;
    padding:20px 14px;
}

.shortcuts-container .generic-card .generic-card-subtitle,
.shortcuts-container #contacts-widget .contact-item .contact-title,
.shortcuts-container #chat-widget .chat-item{
    font-size:14px;
    font-weight:500;
}

.shortcuts-container .generic-card .card-divider{
    margin-top:10px;
    margin-bottom:8px;
}

.shortcuts-container #other-students-goal-widget .widget-item{
    padding:10px;
}

.shortcuts-container #other-students-goal-widget .widget-item .widget-item-head .student-avatar{
    width:28px;
    height:28px;
}

.shortcuts-container #other-students-goal-widget .widget-item .widget-item-head .student-title,
.shortcuts-container #other-students-goal-widget .widget-item .student-goal,
.shortcuts-container #news-widget .news-item .news-title{
    font-weight:500;
}

.shortcuts-container #other-students-goal-widget .widget-item .due-date-wrapper strong,
.shortcuts-container #other-students-goal-widget .widget-item .due-date-wrapper span{
    font-size:10px;
}

.shortcuts-container #other-students-goal-widget .widget-item:not(:last-child){
    margin-bottom:10px;
}

.shortcuts-container #other-students-goal-widget .widget-item .student-goal{
    margin:10px 0;
}

.shortcuts-container #other-students-goal-widget .widget-item .due-date-wrapper{
    padding:5px 0;
    width:162px;
    justify-content: center;
}

.shortcuts-container #news-widget .news-item .news-item-header{
    margin-bottom:4px;
}

.shortcuts-container #news-widget .news-item .news-divider{
    margin:12px 0 5px 0;
}

.shortcuts-container #news-widget .news-item{
    margin:0;
}

.shortcuts-container #news-widget .news-item .news-item-header .news-created_at{
    font-weight:normal;
}

.shortcuts-container #news-widget .view-all-button{
    height:38px;
    font-size:14px;
}

.shortcuts-container #other-members-widget .other-users .view-all-button{
    margin-top:18px;
    height:38px;
    font-size:14px;
}

.shortcuts-container #contacts-widget .contact-item .contact-avatar,
.shortcuts-container #chat-widget .chat-item .chat-avatar{
    width:44px;
    height:44px;
    margin-right:12px;
}

@media(max-width:768px){
    .shortcuts-container{
        flex-direction:column;
        gap:10px;
    }
    .shortcuts-left-column, .shortcuts-right-column{
        flex: 0 0 100%;
        width: 100%;
        gap:10px;
    }
    .shortcuts-left-column .generic-card, .shortcuts-right-column .generic-card{
        padding:10px 14px;
    }
    .shortcuts-container .generic-card .generic-card-subtitle,
    .shortcuts-container #chat-widget .chat-item,
    .shortcuts-container #other-members-widget .other-users .view-all-button{
        font-size:12px;
    }
    .shortcuts-container #events-widget .event-item .event-title,
    .shortcuts-container #chat-widget .chat-item .chat-title,
    .shortcuts-container #other-members-widget .other-users .section-title{
        font-size:12px!important;
        font-weight:500!important;
    }
    .shortcuts-container .generic-card .card-divider{
        margin-bottom:10px;
    }
    .shortcuts-container #events-widget .event-item,
    .shortcuts-container #notifications-widget .notification-item{
        margin-bottom:12px;
    }
    #contacts-widget .contact-item:not(:last-child){
        margin-bottom:17px;
    }
    .shortcuts-container #news-widget .news-item .news-title,
    .shortcuts-container #notifications-widget .notification-item .texts .notification-text{
        font-size:12px;
    }
    .shortcuts-container #news-widget .view-all-button{
        font-size:12px;
        margin-top:20px;
    }
    .shortcuts-container #news-widget .news-item .news-divider{
        margin:12px 0 3px 0;
    }
    .shortcuts-container #chat-widget .view-all-button{
        font-size:12px;
        height:38px;
    }
    .shortcuts-container #other-members-widget{
        padding-right:14px;
        padding-left:14px;
    }
    .shortcuts-container #other-members-widget .other-users .user-item{
        width: 48px;
        flex: 0 0 48px;
        max-width: 48px;
    }
}


