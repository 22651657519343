.profile-summary-view-wrapper.__narrow {
  text-align: center;
}

.profile-summary-view-wrapper.__narrow .profile-picture-area{
  border-radius: 50%;
  width: 62px;
  height: 62px;
  margin: 0 auto;
  margin-bottom: 10px;
  overflow: hidden;
}

.profile-summary-view-wrapper.__wide .profile-picture-area{
  border-radius: 50%;
  width: 112px;
  height: 112px;
  margin: 0 auto;
  margin-bottom: 14px;
  overflow: hidden;
}

.profile-summary-view-wrapper .profile-picture-area .image-wrapper{
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}

.profile-summary-view-wrapper .profile-picture-area img{
  max-width: 100%;
  height: auto;
}

/* @LEFT SIDE - Titles */
.profile-summary-view-wrapper.__wide .profile-title .fullname {
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: bold;
  color: #3f526d;
}
.profile-summary-view-wrapper.__narrow .profile-title .fullname {
  margin-bottom: 3px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  color: #3f526d;
}

.profile-summary-view-wrapper .profile-title .title-line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-summary-view-wrapper.__wide .profile-title .title-line .title{
  color: #3f526d;
  font-size: 16px;
  opacity: 0.68;
}

.profile-summary-view-wrapper.__narrow .profile-title .title-line .title{
  font-size: 16px;
  color: rgba(63, 82, 109, 0.5);
  font-weight: 600;
  line-height: 16px;
}

.profile-summary-view-wrapper .profile-title .verified-user-icon {
  color: #2d97ff;
  width: 12px;
  height: 12px;
  margin-left: 4px;
}


.profile-summary-view-wrapper.__wide .user-summary-count-wrapper{ margin-top: 40px; }
.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper{ margin-top: 10px; }

.profile-summary-view-wrapper .user-summary-count-wrapper .summary-item {
  display: flex;
  align-items: center;
}

.profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .count {
  margin-left: 21px;
  margin-right: 4px;
  font-size: 18px;
  color: #3f526d;
  font-weight: bold;
}

.profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .item-name {
  font-size: 18px;
  color: rgba(63, 82, 109, 0.5);
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item .count {
  margin-left: 10px;
  color: rgba(63, 82, 109, 0.5);
  font-size: 16px;
  font-weight: 600;
}

.profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item {
  margin-bottom: 10px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 64px;
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper {
  display: inline-block;
  text-align: center;
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item{
  box-shadow: none;
  margin: 0;
  padding: 6px 12px;
  display: inline-block;
  float: left;
  background-color: unset;
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item:nth-child(3){
  clear: left; 
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item > *{
  vertical-align: middle;
}

@media screen and (max-width: 1024px) {
  .profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item img {
    width: 24px;
    height: 24px;
  }

  .profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item .count {
    font-size: 14px;
    margin-left: 8px;
  }

  .profile-summary-view-wrapper.__narrow .profile-title .fullname {
    font-weight: 500;
  }

  .profile-summary-view-wrapper.__narrow .profile-title .title-line .title {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .profile-summary-view-wrapper.__narrow .user-summary-count-wrapper {
    display: flex;
    align-items:  center;
  }

  .profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding: 6px 6px;
  }

  .profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item:first-child{
    padding-left: 0;
  }

  .profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item:last-child{
    padding-right: 0;
  }
}