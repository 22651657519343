.border-colored-text-field-wrapper{
    position:relative;
    display:flex;
    align-items:center;
    width:100%;
}

.border-colored-text-field-container .border-colored-text-field-icon{
    position:absolute;
    right:20px;
}

.student-search-form{
    margin-bottom:20px;
}

.student-search-input,
.text-field-search-input {
    border-radius:6px!important;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width:100%;
    padding:15px 55px 15px 15px;
    border-style:solid;
}

.student-search-input input,
.text-field-search-input input{
    padding:18px 20px!important;
}


.student-search-input input::placeholder,
.text-field-search-input input::placeholder {
    color: rgba(63, 82, 109,0.5)!important;
}

.border-colored-text-field-container .border-colored-text-field-icon img.student-search-icon{
    width:20px;
    height:20px;
    margin-right:5px;
}

@media screen and (max-width: 576px) {
    .border-colored-text-field-container .text-field-search-input {
        height: 32px;
        font-size: 12px;
        border-color: #3f526d!important;
        border-width: 0.5px!important;
        padding: 9px 20px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }

    .border-colored-text-field-container .search-icon {
        font-size: 18px;
    }
}