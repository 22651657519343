#mark-all-notifications-as-read-button {
    text-transform: none;
    font-weight: 600;
    font-size: 16px;
    height: 44px;
}
  
#notifications-page .search-area .search-area-textfield {
    height: 60px;
    width:100%;
    border-radius:8px;
    border-style:solid;
    border-width:1px;
    padding:0 55px 0 20px;
    font-size: 18px;
}

#notifications-page .search-area .search-area-textfield::placeholder {
    font-weight: 600;
    color: rgba(63, 82, 109, 0.5);
}

#notifications-page .search-area .search-icon {
    font-size: 34px;
}
  
#notifications-page .notification-list {
    margin: 20px 0 40px 0;
}
  
#notifications-page .notification-list .notification-group {
    margin-top: 20px;
}
  
#notifications-page .notification-list .notification-group .group-title {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}
  
#notifications-page .notification-list .notification-group .group-title .group-title-text{
    font-size: 18px;
    font-weight: 600;
    color: #3f526d;
}
  
#notifications-page .notification-list .notification-group .group-title .clear-all-button {
    font-size: 18px;
    padding-right: 0;
    text-decoration: underline;
    text-transform: unset;
    font-weight: 600;
}
  
#notifications-page .notification-list .item-list {
    
}
  
#notifications-page .notification-list .item-list .notification-item:not(:last-child) {
    margin-bottom: 10px;
}
  
#notifications-page .notification-list .item-list .notification-item {
    border-radius: 6px;
    padding: 14px 20px;
    position: relative;
    background-color: #f9f8fd;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-avatar {
    margin-top: 11px;
}
  
  /* CONTENTS */
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section {
    padding-left: 20px;
    display: flex;
    flex-wrap: nowrap;
    flex: auto;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area {
    flex: auto;
    padding-right: 37px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area strong {
    font-weight: 600;
    font-size: 18px;
    color: #3f526d;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #3f526d;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area p mark {
    background-color: unset;
    color: #3a60d7;
}
  
  /* ATTACHMENTS */
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -10px;
    margin-bottom: -10px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .attachment-item {
    padding: 10px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments a{
    text-decoration: none;
  
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments button {
    color: #327095;
    text-transform: none;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .filename {
    font-size: 16px;
    font-weight: 600;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments button .file-size {
    display: block;
    font-size: 12px;
    color: #79869f;
    text-align: left;
    font-weight: normal;
}
  
  /* Date and Remove Section */
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area b{
    color: #3f526d;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    position: relative;
    top: -4px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area .remove-button {
    position: absolute;
    right: 9px;
    bottom: 6px;
}
  
#notifications-page .pagination-wrapper {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 1024px) {
    #mark-all-notifications-as-read-button {
        width: 160px;
        height: 38px;
        font-size: 12px;
    }

    #notifications-page {
        box-shadow: unset!important;
        border: 0;
        background-color: transparent;
        padding: 0;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    #notifications-page .notification-list .notification-group .group-title .group-title-text {
        font-size: 14px;
        font-weight: 500;
    }

    #notifications-page .notification-list .notification-group .group-title .clear-all-button {
        font-size: 14px;
    }

    #notifications-page .notification-list .notification-group .group-title {
        margin-bottom: 20px!important;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-avatar {
        width: 38px!important;
        height: 38px!important;
        border: 0!important;
        padding: 0!important;
        margin: 0;
        margin-top: 7px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area strong {
        font-size: 14px;
        font-weight: 500;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area p {
        font-size: 14px;
        line-height: 22px;
        font-weight: normal;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area b {
        font-size: 12px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .filename {
        font-weight: 500;
        font-size: 14px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .attachment-item img {
        width: 18px;
        height: 22px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area .remove-button img {
        width: 16px;
        height: 16px;
    }
}

@media screen and (max-width: 576px) {
    #mark-all-notifications-as-read-button {
        height: 32px;
        width: 146px;
    }

    #notifications-page .notification-list .notification-group .group-title {
        margin-bottom: 3px!important;
    }

    #notifications-page .notification-list .notification-group .group-title .group-title-text {
        font-size: 12px;
    }

    #notifications-page .notification-list .notification-group .group-title .clear-all-button {
        font-size: 12px;
    }

    #notifications-page .notification-list .item-list .notification-item {
        border-bottom: 0.5px solid rgba(63, 82, 109, 0.28);
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 8px;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        background-color: transparent;
    }
    
    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-avatar {
        width: 28px!important;
        height: 28px!important;
        border: 0!important;
        padding: 0!important;
        margin: 0;
        margin-top: 7px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area {
        padding-right: 0;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .texts {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        align-items: center;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area strong {
        margin-right: 3px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area p {
        font-size: 12px;
        font-weight: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: -webkit-calc(100vw - 200px);
        width: calc(100vw - 200px);
    }

    #notifications-page .search-area .search-area-textfield {
        height: 32px;
        font-size: 12px;
        border-color: #3f526d!important;
        border-width: 0.5px!important;
        padding: 9px 20px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }

    #notifications-page .search-area .search-icon {
        font-size: 18px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area b {
        position: absolute;
        top: 36px;
        left: 48px;
        font-size: 10px;
        font-weight: normal;
        letter-spacing: normal;
        line-height: normal;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area .remove-button {
        bottom: unset;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments {
        margin-top: 20px;
        margin-bottom: -8px;
        margin-left: -40px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .attachment-item {
        padding:0;
        flex: 1 1 100%;
        max-width: 100%;

    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .filename {
        font-size: 12px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments button .file-size {
        font-size: 10px;
    }
}