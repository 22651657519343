#news-widget {
    width: 290px;
}

/*#news-widget .news-item{*/
/*    display: flex;*/
/*    align-items: center;*/
/*    flex-wrap: nowrap;*/
/*    justify-content: flex-start;*/
/*    margin-bottom: 18px;*/
/*    position: relative;*/
/*}*/

#news-widget .news-item{
    margin-bottom:11px;
}

#news-widget .news-item .news-item-header{
    display:flex;
    justify-content: space-between;
    align-items:center;
    margin-bottom:9px;
}

#news-widget .news-item .news-title{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

#news-widget .news-item .news-content{
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #3f526d;
}

#news-widget .news-item .news-item-header .news-created_at{
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(63, 82, 109, 0.5);
}

#news-widget .news-item .news-divider{
    height: 1px;
    background: rgba(63, 82, 109, 0.12);
    width:80%;
    margin:10px 0;
}

#news-widget .view-all-button {
    text-transform: none;
    font-size: 16px;
    margin-top: 22px;
    height:44px;
}

#news-widget .empty-news-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 246px;
}

#news-widget .empty-news-wrapper span {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: rgba(63, 82, 109, 0.5);
    font-weight: 600;
}
