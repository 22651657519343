.portfolio-body{
    padding:40px 60px 60px 60px;
    max-width:1000px;
}

.portfolio-header{
    display:flex;
    align-items:center;
    border-bottom:1px solid rgba(63, 82, 109, 0.28);
    padding-bottom:30px;
}

.portfolio-header .portfolio-image{
    height:182px;
    width:182px;
    margin-right:60px;
}

.verified-icon{
    height:18px;
    width:18px;
    margin-left:14px;
}

.portfolio-body h4,
.portfolio-body h3,
.portfolio-body p,
.portfolio-body span{
    font-family: OpenSans,sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin:0;
}

.portfolio-header h3{
    font-size: 46px;
    font-weight: 600;
    color:#3f526d;
    margin-bottom:2px;
}

.portfolio-header span:first-child{
    font-weight: 600;
    margin-bottom:15px;
}

.portfolio-header span:last-child,
.portfolio-header span:first-child{
    font-size: 24px;
    color:#3f526d;
    opacity:0.68;
}

.portfolio-information{
    display:flex;
    flex-direction:column;
    gap:15px;
}

.portfolio-body h4{
    font-size: 20px;
    font-weight: 600;
    color:#3f526d;
    margin-top:36px;
}

.portfolio-body p{
    font-size: 20px;
    color:#3f526d;
    margin:0;
    padding:12px 26px 31px 0;
    border-bottom:1px solid rgba(63, 82, 109, 0.28);
    line-height:1.6;
}

.portfolio-body .user-summary-items{
    display:flex;
    justify-content: space-between;
    align-items:center;
    padding:31px 0;
    border-bottom:1px solid rgba(63, 82, 109, 0.28);
    gap:26px;
}

.portfolio-body .summary-item{
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 10px;
    border: solid 0.3px rgba(63, 82, 109, 0.28);
    background-color: #fff;
    width:25%;
    height:64px;
}

.portfolio-body .summary-item img{
    width:38px;
    height:38px;
    margin-right:16px;
    margin-left:5px;
}

.portfolio-body .summary-item-total,
.portfolio-body .summary-item-type{
    font-size:18px;
    color:#3f526d;
}

.portfolio-body .summary-item-total{
    font-weight:600;
}

.portfolio-body .summary-item-type{
    color:rgba(63, 82, 109, 0.5);
}

.portfolio-body .achievements-wrapper{
    display:flex;
    align-items:center;
    gap:50px;
    padding-bottom:85px;
    border-bottom:1px solid rgba(63, 82, 109, 0.28);
}

.portfolio-body .achievement{
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-top:19px;
}

.portfolio-body .achievement-text{
    font-size: 16px;
    font-weight: 600;
    opacity:0.74;
    color:#3f526d;
}

.achievement-image{
    height:120px;
    margin-top:20px;
}

.portfolio-body .goal-items{
    display:flex;
    flex-direction:column;
    gap:10px;
}

.portfolio-body .goal-wrapper{
    border-radius: 8px;
    border: solid 0.3px #979797;
    padding:12px 20px 12px 30px;
}

.portfolio-body .goal-wrapper:first-of-type{
    margin-top:20px;
}

.portfolio-body .goal-item{
    display:flex;
    align-items:center;
    justify-content: space-between;
}

.portfolio-body .goal-text{
    display:flex;
    align-items:center;
}

.portfolio-body .goal-text img{
    width:18px;
    height:18px;
    margin-right:18px;
}

.portfolio-body .goal-text span{
    font-size: 16px;
    font-weight: 600;
    color:#3f526d;
}

.portfolio-body .goal-item .due-date{
    border-radius: 6px;
    background-color: #f9f8fd;
    padding:8px 15px 7px 15px;
    display:inline-block;
    width: 200px;
    text-align: center;
}

.portfolio-body .goal-item .due-date span{
    font-family: OpenSans,sans-serif;
    font-size: 12px;
    text-align: center;
    color: #3f526d;
}

.portfolio-body .goal-item .due-date span:first-child{
    font-weight:600;
}

.portfolio-body .goal-completed{
    padding:8px 68px;
    border-radius:6px;
    background-color: #51ce32;
    color:#fff;
    font-size: 12px;
    font-weight: 600;
}

.portfolio-body .goal-picture-container{
    width:100%;
    display:flex;
    align-items:center;
}

.portfolio-body .goal-picture-container img{
    width:600px;
    margin:50px auto;
}

@media(max-width:1024px){
    .portfolio-body{
        padding:20px 40px 40px 40px;
    }
    .portfolio-header{
        padding-bottom:20px;
    }
    .portfolio-header .portfolio-image{
        width:62px;
        height:62px;
        margin-right:20px;
    }
    .portfolio-header h3{
        font-size:18px;
        font-weight:500;
    }
    .portfolio-header span:last-child, .portfolio-header span:first-child{
        font-size:14px;
    }
    .verified-icon{
        width:14px;
        height:14px;
    }
    .portfolio-information{
        gap:6px;
    }
    .portfolio-body h4{
        margin-top:14px;
        font-size:16px;
        font-weight:500;
    }
    .portfolio-body p{
        font-size:14px;
        line-height:1.71;
        padding: 8px 26px 16px 0;
    }
    .portfolio-body .user-summary-items{
        padding:20px 0;
        gap:20px;
    }
    .portfolio-body .summary-item img{
        width:18px;
        height:18px;
        margin-right:10px;
    }
    .portfolio-body .summary-item-total, .portfolio-body .summary-item-type{
        font-size:14px;
    }
    .portfolio-body .summary-item-total{
        font-weight:500;
    }
    .portfolio-body .summary-item{
        height:44px;
    }
    .portfolio-body .achievements-wrapper{
        padding-bottom:20px;
    }
    .portfolio-body .achievement{
        margin-top:23px;
    }
    .portfolio-body .achievement-text,
    .portfolio-body .goal-text span{
        font-size: 14px;
        font-weight: 500;
    }
    .achievement-image{
        height:100px;
    }
    .goal-items{
        margin-top:23px;
    }
    .portfolio-body .goal-wrapper{
        margin-top:0;
        padding:9px 10px 9px 20px;
    }
    .portfolio-body .goal-text img{
        margin-right:20px;
    }
    .portfolio-body .goal-picture-container img{
        padding:0 100px;
        margin:43px auto 16px auto;
    }
    .portfolio-body .goal-completed{
        font-weight:500;
    }
    .portfolio-body .goal-completed-container{
        margin-top:10px;
    }
}

@media(max-width:768px) {
    .portfolio-body {
        padding: 3px 17px 14px 17px;
    }

    .download-dialog-button {
        top: 14px;
        right: 17px;
        width: 26px;
        height: 26px;
    }

    .portfolio-header .portfolio-image {
        width: 38px;
        height: 38px;
        margin-right: 14px;
    }

    .download-dialog-button img {
        height: 14px;
    }

    .portfolio-body .summary-item img {
        margin: 0 0 2px 0;
    }

    .portfolio-header h3 {
        font-size: 12px;
    }

    .portfolio-header span:last-child, .portfolio-header span:first-child {
        font-size: 10px;
    }

    .verified-icon {
        width: 12px;
        height: 12px;
    }

    .portfolio-header {
        padding-bottom: 11px;
    }

    .portfolio-body h4 {
        font-size: 12px;
        margin-top: 9px;
    }

    .portfolio-body p {
        font-size: 12px;
        line-height: 1.5;
        padding: 10px 0;
    }

    .portfolio-body .summary-item {
        flex-direction: column;
        justify-content: center;
        padding-left: 0;
    }

    .portfolio-body .summary-item-total, .portfolio-body .summary-item-type {
        font-size: 10px;
    }

    .portfolio-body .user-summary-items {
        gap: 8.5px;
        padding: 10px 0;
    }

    .portfolio-body .achievement {
        margin-top: 15px;
    }

    .portfolio-body .achievement-text {
        font-size: 10px;
    }

    .achievement-image {
        height: 60px;
        margin-top: 13px;
    }

    .portfolio-body .achievements-wrapper {
        padding-bottom: 14px;
        row-gap: 10px;
        column-gap: 20px;
        flex-wrap: wrap;
    }

    .portfolio-body .goal-items {
        margin-top: 0;
    }

    .portfolio-body .goal-wrapper:first-of-type {
        margin-top: 10px;
    }

    .portfolio-body .goal-item {
        flex-direction: column;
        align-items:flex-start;
        gap:10px;
    }
    .portfolio-body .goal-wrapper{
        padding:12px 10px 7px 10px;
    }
    .portfolio-body .goal-text img{
        width:16px;
        height:16px;
        margin-right:8px;
    }
    .portfolio-body .goal-text span{
        font-size:12px;
    }
    .portfolio-body .goal-completed{
        font-size:10px;
        padding: 8px 26px;
    }
    .portfolio-body .goal-picture-container img{
        width:100%;
        margin:26px 0 4px 0;
        padding:0 6px;
    }
}

