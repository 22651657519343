#chat-widget {
    width: 290px;
}

#chat-widget .chat-item{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: relative;
    padding:0!important;
    width:100%;
}

#chat-widget .chat-item:not(:last-child) {
    margin-bottom: 16px;
}

#chat-widget .chat-item .chat-avatar {
    width: 54px;
    height: 54px;
    padding: 3px;
    margin-right: 17px;
}

#chat-widget .chat-item .chat-title{
    text-transform: none!important;
    font-size: 18px!important;
    font-weight: 600!important;
    color: #3f526d;
}

#chat-widget .view-all-button {
    text-transform: none;
    font-size: 16px;
    margin-top:4px;
    margin-bottom:10px;
    height:44px;
}
