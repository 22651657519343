#other-members-widget{
    padding-left: 27px;
    padding-right: 24px;
}

/* @OTHER USERS */
#other-members-widget .other-users .section-title {
    margin-bottom: 20px;
    font-size: 16px;
    color: #3f526d;
    font-weight: 600;
}

#other-members-widget .other-users .users-wrapper {
    margin:0 -6px;
}

#other-members-widget .other-users .user-item {
    width: 58px;
    flex: 0 0 58px;
    max-width: 58px;
    padding: 4px;
}

#other-members-widget .other-users .user-item .user-avatar{
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

#other-members-widget .other-users .view-all-button {
    text-transform: none;
    margin-top: 30px;
    font-size: 16px;
}

#other-members-widget .empty-members-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 246px;
}

#other-members-widget .empty-members-wrapper span {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: rgba(63, 82, 109, 0.5);
    font-weight: 600;
}
