.add-file-dialog-body{
    display:flex;
    flex-direction:column;
    margin:10px 21px;
}

.add-file-dialog-body img{
    width:70px;
}

.add-file-dialog-title{
    font-size: 18px;
    font-weight: 600;
    color:#3f526d;
    margin:0;
}

.add-file-dropzone-container{
    /*padding: 25px 255px 67px 91px;*/
    border-radius: 8px;
    border: dashed 0.5px rgba(37, 56, 115, 0.5);
    background-color: #f9f8fd;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding:25px 92px 20px 93px;
    margin-top:20px;
    margin-bottom:40px;
}
/*git commit -m "[finishes #798344] Add AnswerDialog | ShareToFeed | SendToChat dialogs, Badges tab data, discussion forum tab data design, some improvements on components, Add functionality to add comments"*/
.add-file-dropzone-container p{
    font-size: 16px;
    color: #253873;
    margin-top:25px;
}

.add-file-dialog-body button{
    text-transform:none!important;
    line-height:normal!important;
}

.add-file-button{
    font-size: 16px!important;
    font-weight: 600!important;
    padding:13px 39px!important;
    border-radius:8px!important;
}

.add-file-upload-buttons{
    display:flex;
    gap:10px;
    margin-bottom:22px;
}

.upload-file-button, .cancel-file-button{
    font-size: 18px!important;
    font-weight: 600!important;
    padding:15px 48px!important;
    border-radius:8px!important;
    width: 124px;
    height: 48px;
}

.cancel-file-button{
    color:#3f526d!important;
}

.file-list{
    margin-bottom:40px;
}

.file-list-item{
    display:flex;
    justify-content: space-between;
    align-items:center;
}

.file-list-item div{
    display:flex;
    align-items:center;
}

.file-list-item .file-name,
.file-list-item .file-upload-time{
    font-family: OpenSans,sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.file-list-item .file-name{
    font-size: 18px;
    margin-left:20px;

}

.file-list-item .file-upload-time{
    opacity: 0.6;
    font-size: 14px;
    margin-right:18px;
}

.file-list-item .file-icon{
    width:32px;
    height:32px;
}

@media(max-width:1024px){
    .add-file-button,
    .add-file-dialog-title,
    .upload-file-button, .cancel-file-button{
        font-size:14px!important;
        font-weight:500!important;
    }
    .upload-file-button,.cancel-file-button,
    .add-file-button{
        width:120px;
        height:40px;
    }
    .add-file-dropzone-container p{
        font-size:12px!important;
    }
    .add-file-dropzone-container{
        margin-bottom:20px;
    }
    .add-file-dropzone-container .MuiButton-label{
        white-space:nowrap;
    }
    .add-file-dialog-body .file-list-item .file-name{
        font-size:14px;
        font-weight:500;
        margin-left:12px;
    }
    .add-file-dialog-body .file-list-item .file-upload-time{
        font-size:12px;
    }
}

@media(max-width:768px){
    .add-file-dialog-title{
        font-size:12px!important;
    }
    .add-file-button, .upload-file-button, .cancel-file-button{
        width:84px;
        height:28px;
        font-size:10px!important;
    }
    .add-file-dropzone-container p{
        font-size:10px!important;
        color:#253873;
        margin-top:10px;
        margin-bottom:0;
        text-align:center;
        opacity:0.5;
    }
    .add-file-dropzone-container{
        margin-top:10px;
        margin-bottom:16px;
        padding:10px;
    }
    .add-file-upload-buttons{
        justify-content:center;
        margin-bottom:0;
    }
    .add-file-dialog-body{
        margin:7px 17px 17px 17px;
    }
    .add-file-dialog-body .file-list-item .file-name{
        font-size:12px;
        margin-left:8px;
    }
    .add-file-dialog-body .file-list-item .file-upload-time{
        font-size:10px;
        text-align:right;
        margin-right:0;
    }
    .add-file-dialog-body .file-list-item .delete-file-button{
        margin-right:-10px;
    }
    .add-file-dialog-body .file-list-item .file-icon{
        width:26px;
        height:26px;
    }
}
