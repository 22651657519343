
#create-content-footer-section-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
}

#create-content-footer-section-wrapper .left-side {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

#create-content-footer-section-wrapper .left-side .section-item {
    font-size: 14px;
    text-transform: none;
    color: #3f526d;
    font-weight: normal;
}

#create-content-footer-section-wrapper .left-side hr {
    margin: 0 20px;
    height: 22.5px;
    align-self: center;
}

#create-content-footer-section-wrapper .left-side .section-item img {
    margin-right: 6px;
}

#create-content-footer-section-wrapper .right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
}

#create-content-footer-section-wrapper .right-side .action-button {
    margin-left: 14px;
    text-transform: none;
    min-width: 100px;
    border-radius: 6px;
}

@media(max-width:1024px){
    #create-content-footer-section-wrapper .left-side .section-item{
        font-size:12px;
    }
    #create-content-footer-section-wrapper .left-side .section-item img{
        width:18px;
        height:18px;
    }
    #create-content-footer-section-wrapper .right-side .action-button{
        font-size:14px;
        font-weight:500;
    }
    #create-content-footer-section-wrapper .left-side hr{
        margin:0 11px;
    }
}

@media(max-width:768px){
    #create-content-footer-section-wrapper .right-side .action-button{
        min-width:unset;
    }
    #create-content-footer-section-wrapper .left-side hr{
        margin:0;
    }
    #create-content-footer-section-wrapper .left-side{
        margin:0 -4px;
    }
}
