
.story-item{
    position:relative;
}

.story-item .main-image{
    width: 100px;
    height: 100px;
    border-radius: 8px;
    object-fit: cover;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
}

.stories-images-container{
    display:flex;
    gap:8px;
}

@media(max-width:1400px){
    .story-item .main-image{
        width:80px;
        height:80px;
    }
}

.story-item .mini-image{
    width: 26px;
    height: 26px;
    border-radius: 50%;
    object-fit: contain;
    position:absolute;
    top:6px;
    left:6px;
    border-width: 2px;
    border-style: solid;
}

.stories-header h3{
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: #3f526d;
    margin:0;
}

.stories-header button{
    font-size: 14px;
    font-weight:600;
    text-decoration: underline;
    text-transform: none;
}

.stories-container{
    margin-bottom:24px;
    margin-top:15px;
    gap:30px;
    flex-wrap:nowrap!important;
}

/* CREATE STORY ITEM */
.create-story-button {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    width: 100px;
    height: 100px;
    border-radius: 8px!important;
    text-transform: none;
}

@media(max-width:1400px){
    .create-story-button{
        width:80px;
        height:80px;
    }
}

.create-story-button > .MuiButton-label {
    flex-wrap: wrap;
}

.create-story-button svg {
    font-size: 32px;
}

.create-story-button .add-to-story-text{
    display: block;
    font-size: 12px;
    font-weight: 600;
    flex: 1 1 100%;
    width: 100%;
    max-width: 100%;
    text-align: center;
    color: #3f526d;
    margin-top: 10px;
    text-transform: none;
}

/* Share Story Modal */

.share-story-wrapper {
    min-width: 320px;
    min-height: 200px;
    padding: 30px 50px;
    width: 70vw;
}

.share-story-wrapper .target-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.share-story-wrapper .target-group .target-group-selectbox{
    width: 140px;
}

.share-story-wrapper .story-images-wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
}

.share-story-wrapper .story-images-wrapper .slider-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.share-story-wrapper .story-images-wrapper .slick-slide {
    padding: 0 11px;
}

.share-story-wrapper .story-images-wrapper .carousel-item {
    position: relative;
    background-color: rgba(208, 194, 163, 0.2);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 500px;
    max-height: 100%;
}

.share-story-wrapper .story-images-wrapper .carousel-item .remove-button {
    position: absolute;
    right: 0;
    top: 0;
}

.share-story-wrapper .story-images-wrapper .slick-list {
    margin: 0 -20px;
    width: 100%;
}

.share-story-wrapper .story-images-wrapper .slick-prev {
    top: unset;
    transform: unset;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
}

.share-story-wrapper .story-images-wrapper .slick-arrow {
    width: 56px;
    height: 56px;
}

.share-story-wrapper .story-images-wrapper .slick-arrow:before {
    content: ''!important;
    display: none;
}

.share-story-wrapper .story-images-wrapper .slick-arrow svg {
    font-size: 32px;
}

.share-story-wrapper .story-images-wrapper .slick-arrow:not(.slick-disabled) svg {
    color: #676767;
}

.share-story-wrapper .story-images-wrapper .slick-arrow.slick-disabled svg {
    color: #d3d3d3;
}

.share-story-wrapper .story-images-wrapper .next-arrow-icon {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.share-story-wrapper .actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.share-story-wrapper .actions > button {
    text-transform: unset;
}

.share-story-wrapper .actions > button:first-child {
    margin-right: 10px;
    color: #3f526d;
    width: 170px;
    flex: 0 0 170px;
    max-width: 170px;
    background-color: #f9f8fd;
}

.share-story-wrapper .actions > button:last-child {
    margin-left: 10px;
    width: 200px;
    flex: 0 0 200px;
    max-width: 200px;
}

@media(max-width:1024px){
    .create-story-button{
        width:100px;
        height:100px;
    }
    .create-story-button .add-to-story-text{
        font-weight:500;
    }
    .story-item .main-image{
        width:100px;
        height:100px;
    }
    .stories-header{
        display:none!important;
    }
    .stories-container{
        margin-top:0;
        gap:16px;
    }
    .share-story-wrapper .actions > button{
        font-size:14px!important;
        font-weight:500!important;
        height:48px;
    }
    .share-story-wrapper .story-images-wrapper{
        margin-bottom:65px;
    }
}

@media(max-width:768px){
    .create-story-button,
    .story-item .main-image{
        height:160px;
    }
    .stories-header{
        display:flex!important;
        margin-bottom:12px!important;
    }
    .stories-container{
        margin-bottom:10px;
        gap:8px;
    }
    .story-item .mini-image{
        top:10px;
        left:10px;
    }
    .stories-header h3,
    .stories-header button{
        font-size:12px!important;
        font-weight:500!important;
    }
    .share-story-wrapper .actions > button{
        font-size:12px!important;
        height:28px;
    }
    .share-story-wrapper .actions > button:first-child{
        width:84px;
        flex: 0 0 84px;
        max-width: 84px;
    }
    .share-story-wrapper .actions > button:last-child{
        width:120px;
        flex: 0 0 120px;
        max-width: 120px;
    }
    .share-story-wrapper{
        padding:0 22px 22px 22px;
    }
    .share-story-wrapper .story-images-wrapper{
        margin-bottom:20px;
    }
    .share-story-wrapper .story-images-wrapper .slick-list{
        flex:1;
    }
    .stories-slider-container .stories-slider-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }
    .stories-slider-container .stories-slider-wrapper .slick-list {
        width: 100%;
    }
    .stories-slider-container .stories-slider-wrapper .story-item {
        height:160px;
        max-height: 100%;
    }
    .stories-slider-container{
        overflow:hidden;
        margin-right: -16px;
    }
}
