#profile-widget{
  padding-left: 27px;
  padding-right: 24px;
}

#profile-widget .profile-widget-item:not(.profile-summary-view-wrapper){
  margin-top: 40px;
}

#profile-widget .about-me .section-title {
  margin-bottom: 8px;
  font-size: 16px;
  color: #3f526d;
  font-weight: 600;
}

#profile-widget .about-me .section-text {
  line-height: 22px;
  font-size: 12px;
  color: #3f526d;
  letter-spacing: 0;
}

/* @OTHER USERS */
#profile-widget .other-users .section-title {
  margin-bottom: 20px;
  font-size: 16px;
  color: #3f526d;
  font-weight: 600;
}

#profile-widget .other-users .users-wrapper {
  margin:0 -6px;
}

#profile-widget .other-users .user-item {
  width: 58px;
  flex: 0 0 58px;
  max-width: 58px;
  padding: 4px;
}

#profile-widget .other-users .user-item .user-avatar{
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#profile-widget .other-users .view-all-button {
  text-transform: none;
  margin-top: 30px;
  font-size: 16px;
}