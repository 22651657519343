#contacts-widget {
  width: 290px;
}

#contacts-widget .contact-item{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: relative;
  }
  
  #contacts-widget .contact-item:not(:last-child) {
    margin-bottom: 12px;
  }
  
  #contacts-widget .contact-item .contact-avatar {
    width: 54px;
    height: 54px;
    padding: 3px;
    background-color: #f9f8fd;
    border-width: 2px;
    border-style: solid;
    margin-right: 14px;
}
  
  #contacts-widget .contact-item .contact-title{
    font-size: 18px;
    font-weight: 600;
    color: #3f526d;
}