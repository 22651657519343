#notifications-widget {
  width: 290px;
}

#notifications-widget .notifications-clear-all-button{
    text-decoration: underline;
    text-transform: none;
    font-size: 12px;
  }
  
  #notifications-widget .notification-item{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 18px;
    position: relative;
  }
  
  #notifications-widget .notification-item .notification-avatar {
    width: 48px;
    height: 48px;
    padding: 3px;
    background-color: #f9f8fd;
    border-width: 2px;
    border-style: solid;
    margin-right: 15px;
  }
  
  #notifications-widget .notification-item .texts{
    margin-right: 30px;
  }
  
  #notifications-widget .notification-item .texts .notification-text{
    font-size: 14px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    color: #3f526d;
    letter-spacing: 0;
  }
  
  #notifications-widget .notification-item .texts .notification-created_at{
    font-size: 12px;
    opacity: 0.8;
    color: #3f526d;
  }
  
  #notifications-widget .notification-item .notification-close-button{
    position: absolute;
    right:0;
    top:0;
  }
  
  #notifications-widget .notification-item .notification-close-button svg.MuiSvgIcon-root{
    font-size: 16px;
    color: #e7e7e7;
  }
  
  #notifications-widget .view-all-button {
    text-transform: none;
    font-size: 16px;
    margin-top: 2px;
  }
  
  #notifications-widget .empty-notification-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 246px;
  }
  
  #notifications-widget .empty-notification-wrapper img {
      
  }
  
  #notifications-widget .empty-notification-wrapper span {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: rgba(63, 82, 109, 0.5);
    font-weight: 600;
  }