.comments-dialog-body{
    display:flex;
    flex-direction:column;
    margin:1px 6px;
    min-width:300px;
    padding:8px 24px
}

.comments-dialog-title{
    font-size: 18px;
    font-weight: 600;
    color:#3f526d;
    margin:0 0 30px 0;
}

.comment-container{
    display:flex;
    background-color:#f9f8fd;
    border-radius:10px;
    margin-left:18px;
    padding:8px 10px;
}

.comment-container p{
    font-size: 10px;
    line-height: normal;
    color: #3f526d;
    margin:0;
}

.comment-text-container{
    /*margin-left:18px;*/
}

.comments-dialog-create-box-container{
    padding:0 34px 27px 30px;
}

.comment-box-divider{
    margin:0;
    height:1px;
    background-color:rgba(0, 0, 0, 0.46);
    width:100%;
    opacity:0.1;
}

@media(max-width:1024px){
    .comments-dialog-body{
        padding:0 28px 4px 14px;
    }
    .comments-dialog-title{
        font-size:16px;
    }
    .comments-dialog-body .comment-box img{
        width:30px;
        height:30px;
    }
    .comments-dialog-body .comment-box{
        width:100%;
    }
    .comments-dialog-body .comment-container{
        border-radius:6px;
        width:100%;
        margin-left:22px;
    }
    .comments-dialog-body .comment-box h5{
        font-size:14px!important;
        margin:0 0 4px 0!important;
    }
    .comments-dialog-body .comment-container p{
        font-size:14px;
    }
    .comments-dialog-create-box-container{
        padding:0 34px 20px 20px;
    }
    .comments-dialog-create-box-container .create-post-box{
        gap:22px;
    }
    .comments-dialog-create-box-container .create-post-box input{
        padding: 10px 90px 10px 20px;
    }
}

@media(max-width:768px){
    .comment-container{
        margin-left:14px;
        padding:4px 16px 7px 6px;
        border-radius:4px;
        width:100%;
    }
    .single-post-container .comments{
        margin-top:10px;
    }
    .comment-box h5{
        font-size:12px!important;
        font-weight:500!important;
    }
    .single-post-container .load-more-button span{
        font-weight:600;
    }
    .single-post-container .load-more-button{
        margin-top:10px;
        margin-left:35px;
    }
    .comments-dialog-title{
        font-size:12px;
        font-weight:500;
        margin:0 0 20px 0;
    }
    .comments-dialog-body .comment-box img{
        width:26px;
        height:26px;
    }
    .comments-dialog-body .comment-container{
        margin-left:14px;
    }
    .comments-dialog-body .comment-box h5,
    .comments-dialog-body .comment-container p{
        font-size:10px!important;
    }
    .MuiDialogContent-root:first-child{
        padding-top:13px!important;
    }
    .comments-dialog-body{
        padding:0 4px 4px 4px;
    }
    .comments-dialog-create-box-container{
        padding:0 10px 20px 10px;
    }
    .comments-dialog-create-box-container .create-post-box{
        gap:10px;
    }
    .questions-container .comment-container{
        margin-left:4px;
    }
}
