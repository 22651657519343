.not_scrollable {
    overflow: hidden;
}

#root > div {
    max-width: 100%;
    height: unset!important;
}

#root > div:nth-of-type(1) > div:nth-of-type(1) {
    display: none;
}

#main-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-flow: row nowrap;
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh, 1vh) * 100);
  }

#main-container .content-area {
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
    min-width: 0;
}

#main-container .content-area .radius-background {
    position: absolute;
    left: -1px;
    top: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
}

#main-container .content-area .radius-background-2 {
    position: absolute;
    left: -1px;
    bottom: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
}

#main-container .content-area .content-area-wrapper {
    background-color: #fff;
    padding-left: 26px;
    padding-right: 26px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    z-index: 2;
    position: relative;
    padding-bottom: 50px;
}

#main-container.__fullwidth .content-area.content-area-wrapper {
    padding-right: unset;
}

/* Responsive Designs */
@media screen and (max-width: 1024px) {
    #main-container .content-area .radius-background,
    #main-container .content-area .radius-background-2 {
        display: none!important;
    }

    #main-container .content-area .content-area-wrapper {
        padding: 0;
    }

    #main-container .content-area .content-area-wrapper .content-section {
        padding: 20px 28px;
    }

    #main-container:not(.__has-page-header) .content-area .content-area-wrapper {
        padding-top: 64px;
        min-height: calc(100vh - 64px);
        min-height: calc(calc(var(--vh, 1vh) * 100) - 64px);
    }

    #main-container.__has-page-header .content-area .content-area-wrapper {
        padding-top: 122px;
        padding-bottom:130px;
    }
}

@media screen and (max-width: 767px) {
    #main-container .content-area .content-area-wrapper .content-section {
        padding: 20px 16px;
    }
    .landing-page-main-container#main-container.__has-page-header .content-area .content-area-wrapper {
        padding-bottom:0;
    }
    .shortcuts-container .tab-menu{
        display:none!important;
    }
}