#other-students-goal-widget {
  width: 290px;
}

#other-students-goal-widget .widget-item {
    box-shadow: none;
    border: none;
    border-radius: 8px;
    padding: 14px;
  }
  
  #other-students-goal-widget .widget-item:not(:last-child) {
    margin-bottom: 20px;
  }
  
  #other-students-goal-widget .widget-item .widget-item-head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  
  #other-students-goal-widget .widget-item .widget-item-head .student-avatar {
    width: 34px;
    height: 34px;
    margin-right: 10px;
  }
  
  #other-students-goal-widget .widget-item .widget-item-head .student-title {
    font-size: 12px;
    font-weight: 600;
    color: #3f526d;
  }
  
  #other-students-goal-widget .widget-item .student-goal {
    margin: 16px 0 20px 0;
    font-size: 12px;
    font-weight: 600;
    display: block;
    color: #3f526d;
  }
  
  #other-students-goal-widget .widget-item .due-date-wrapper{
    background-color: #fff;
    padding: 7px 15px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    color: #3f526d;
  }
  
  #other-students-goal-widget .widget-item .due-date-wrapper strong{
    margin-right: 4px;
    font-size: 12px;
    font-weight: 600;
  }
  
  #other-students-goal-widget .widget-item .due-date-wrapper span{
    font-size: 12px;
  }