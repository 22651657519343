#events-widget {
  width: 290px;
}

#events-widget .event-item-bottom{
  display:flex;
  justify-content:space-between;
  align-items:center;
}

#events-widget .card-divider{
  margin-bottom:0;
}

#events-widget .event-item{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 16px;
    margin-top:20px;
    position: relative;
  }

#events-widget .event-item-bottom button{
  font-size:12px!important;
  width:140px;
  height:32px;
  text-transform:capitalize!important;
  padding:0!important;
  color:#3f526d!important;
}

#events-widget .event-item-bottom .event-date{
  font-size:10px;
  color:#3f526d;
}

  #events-widget .event-item .event-avatar {
    margin-right: 19px;
    display: block;
  }

  #events-widget .event-item .event-title{
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin-right: 30px;
    color: #3f526d;
  }

  #events-widget .event-item .event-close-button{
    position: absolute;
    right:0;
    top:0;
  }

  #events-widget .event-item .event-close-button svg.MuiSvgIcon-root{
    font-size: 16px;
    color: #e7e7e7;
  }

  #events-widget .empty-events-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 287px;
  }

  #events-widget .empty-events-wrapper img {

  }

  #events-widget .empty-events-wrapper span {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: rgba(63, 82, 109, 0.5);
    font-weight: 600;
  }

  @media(max-width:1024px){
    #events-widget .event-item {
      margin-bottom:8px;
    }
    #events-widget .event-item .event-title{
      font-weight:500;
    }
    #events-widget .event-item-wrapper:first-child .event-item{
      margin-top:8px;
    }
    #events-widget .event-item-wrapper:last-child .event-item-divider{
      display:none;
    }
    #events-widget .event-item-divider{
      margin-top:10px;
      margin-bottom:3px;
      border-bottom-width:0;
      border-color:rgba(63, 82, 109, 0.12);
    }
    #events-widget .event-item img{
      width:14px;
    }
  }
