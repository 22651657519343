.landing-page-main-container .widget-button {
    text-transform: unset;
    width: 120px;
    height: 44px;
}
.landing-page-main-container .pdf-button {
    width: 44px;
    height: 44px;
    min-width: unset;
    margin-left: 20px;
}

#landing-page-container {
    flex-flow: row nowrap;
}

#landing-page-container > .left-side,
#landing-page-container > .right-side {
    flex: 0 0 290px;
    max-width: 290px;
}

#page-header .right-side {
    display:flex;
}

#landing-page-container .content-side {
    flex: 1 1 auto;
    max-width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    min-width: 0;
}

#landing-page-container .content-rightSide-fullWidth {
    padding-right: 0;
}

#landing-page-container .content-leftSide-fullWidth{
    padding-left: 0;
}

@media(max-width:1024px){
    #landing-page-container > .left-side,
    #landing-page-container > .right-side,
    .landing-page-main-container .widget-button {
        display:none;
    }

    #landing-page-container .content-side {
        padding:0!important;
    }

    .landing-page-main-container .pdf-button{
        width:30px;
        height:30px;
        border-radius:6px;
    }

    .landing-page-main-container .pdf-button img{
        width:18px;
        height:20px;
    }
}

@media(max-width:768px){
    .landing-page-main-container .pdf-button{
        margin-left:10px;
    }
}
