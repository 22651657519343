.landing-page-main-container .tab-menu.box-view{
    display:flex;
    align-items:center;
    gap:30px;
    margin-bottom: 7px;
}

.landing-page-main-container .tab-menu .MuiButtonBase-root {
    font-weight: normal;
    padding-left: 17px;
    padding-right: 17px;
}

.landing-page-main-container .tab-menu .MuiButtonBase-root[aria-selected="true"] {
    font-weight: 600;
    /* color: inherit ; */
}

.landing-page-main-container .tab-menu.box-view .TabMenu-root-5{
    padding:0 6px;
}

.filter-button{
    min-width:50px;
    height:50px;
    margin-bottom:24px;
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}

.filter-button img{
    width:24px;
    height:24px;
}

.filter-button button{
    min-width:unset;
    height:100%;
    width:100%;
    border-radius:8px;
    padding:0;
}

.filter-menu .MuiPopover-paper{
    border-radius: 8px!important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    padding:16px 20px!important;
}

.filter-container{
    width:220px;
}

.filter-container .MuiMenu-list{
    padding:0;
}

.filter-menu-header{
    display:flex;
    justify-content: space-between;
    align-items:center;
    border-bottom:solid 0.5px #3f526d;
    margin-bottom:14px;
}

.filter-menu-header h3, .filter-menu-header span{
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;;
}

.filter-menu-header h3{
    font-size: 14px;
    font-weight: 600;
}

.filter-menu-header span{
    font-size:12px;
    text-decoration:underline;
    cursor:pointer;
}

.filter-select-box,
.filter-multiple-select-box{
    width:100%;
    margin-top:11px!important;
    margin-bottom:24px!important;
}

.filter-container label,
.filter-multiple-select-search{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.filter-multiple-select-search input{
    font-size:12px;
}

.filter-select-box label{
    color:rgba(0, 0, 0, 0.3)!important;
}

.filter-multiple-select-box .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    padding:2px 9px;
}

.filter-menu-buttons{
    display:flex;
    align-items:center;
    gap:10px;
}

.filter-container .filter-button{
    font-size: 14px;
    font-weight: 600!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal!important;
    letter-spacing: normal;
    text-align: center;
    text-transform:capitalize!important;
    width:100px;
    height:36px;
    padding:9px 30px!important;
    border-radius: 6px;
}

.filter-container .filter-button:last-child{
    border-width: 0.5px;
    border-style: solid;
    color: #3f526d;
}

.select-box-chips{
    display:flex;
    gap:2px;
}

.MuiChip-root{
    border-radius: 4px!important;
    background-color: rgb(127, 108, 252,0.1)!important;
    font-size: 14px!important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #253873!important;
    /*margin:0 5px 5px 0!important;*/
}

.filter-container .filter-select-box .MuiSelect-select{
    padding-top: 10px;
    padding-bottom: 10px;
}

.filter-select-box .MuiInputLabel-outlined{
    transform: translate(14px, 12px) scale(1)!important;
}

.filter-select-box .MuiInputLabel-outlined.MuiFormLabel-filled{
    display:none;
}

.filter-multiple-select-search .MuiInputBase-input::placeholder{
    color: #3f526d!important;
}

.back-button{
    font-family: OpenSans,sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform:capitalize!important;
    margin-bottom:24px!important;
    padding:14px 20px!important;
    height:50px;
    border-radius:8px!important;
    box-shadow:none!important;
    margin-right:20px!important;
}

.back-button img{
    width:18px;
    height:18px;
}

.tab-menu.answer-view{
    gap:0;
}

.tab-menu.answer-view .filter-button{
    margin-left:10px!important;
}

.my-profile-button{
    padding: 14px 48px!important;
    border-radius: 8px!important;
    text-transform:capitalize!important;
    font-size:16px!important;
    font-weight:600!important;
}

.widget-menu .MuiPopover-paper {
    padding:20px;
    width:220px;
    border-radius:8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
}

.widget-menu-buttons{
    display:flex;
    justify-content:space-between;
    margin-top:16px;
}

.widget-menu-form span{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
}

.widget-menu-buttons .widget-button{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform:capitalize!important;
    box-shadow:none;
    width:84px;
    height:30px;
    border-radius:4px;
}

.widget-menu-buttons .widget-button:last-child{
    background-color: #f6f6f6;
    color:#3f526d;
}

@media(min-width:1024px){
    .mobile-profile-widget{
        display:none;
    }
}

@media(max-width:1024px){
    .landing-page-main-container .tab-menu.box-view{
        margin-bottom:0;
    }
    .mobile-profile-widget{
        background-color:#fff;
        border-radius:8px;
        box-shadow:0 0 15px 0 rgba(0, 0, 0, 0.1);
        padding:20px;
        margin-bottom:20px;
    }
    .mobile-profile-widget .profile-picture-area{
        margin:0!important;
    }
    .mobile-profile-widget .profile-summary-view-wrapper{
        display:flex;
        align-items:center;
    }
    .mobile-profile-widget .profile-title{
        margin-left:10px;
    }
    .mobile-profile-widget .user-summary-count-wrapper{
        margin-top:0!important;
        margin-left:6px;
        display:flex;
        justify-content:flex-end;
        gap:20px;
        flex:1;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .profile-picture-area{
        width:40px;
        height:40px;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .profile-title .fullname,
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .count{
        font-size:14px;
        font-weight:500;
        text-align:left;
        margin-bottom:0!important;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .count{
        margin-left:10px;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .item-name{
        font-size:14px;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .profile-title .title-line .title{
        font-size:12px;
        white-space:nowrap;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item{
        box-shadow:none!important;
        height:unset;
        margin-bottom:0;
        padding:0;
    }
    .mobile-profile-widget .profile-summary-view-wrapper .profile-picture-area img{
        width:40px;
        height:40px;
    }
    .mobile-profile-widget .user-summary-count-wrapper img{
        width:24px;
    }
    #page-header .filter-button{
        width:30px;
        height:30px;
        box-shadow:none;
        min-width:unset;
        border-radius:6px;
        margin-bottom:0;
    }
    #page-header .filter-button svg{
        width:18px;
        height:18px;
    }
    .filter-menu-header h3,
    .filter-container label, .filter-multiple-select-search,
    .filter-container .filter-button{
        font-weight:500!important;
    }
    .filter-menu-header{
        padding-bottom:10px;
    }
    .filter-menu ul{
        padding:0;
    }
    .filter-select-box-list-item{
        font-size:14px!important;
        padding-left:10px!important;
    }
}

@media(max-width:768px){
    .mobile-profile-widget .user-summary-count-wrapper{
        flex:0 0 100%;
        margin-top:14px!important;
        justify-content:flex-start;
        margin-left:0;
        gap:15px;
    }
    .mobile-profile-widget .profile-summary-view-wrapper{
        flex-wrap:wrap;
    }
    .mobile-profile-widget .profile-summary-view-wrapper .profile-picture-area img,
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .profile-picture-area{
        width:32px;
        height:32px;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .profile-title .fullname{
        font-size:12px;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .count,
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .item-name{
        font-size:10px;
    }
    .mobile-profile-widget{
        padding:14px 10px;
        margin-bottom:10px;
    }
    .mobile-profile-widget .user-summary-count-wrapper img{
        width:16px;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .count{
        margin-left:6px;
    }
    .landing-page-main-container .tab-menu .MuiButtonBase-root{
        font-size:12px;
        padding:6px 4px;
    }
    .landing-page-main-container .tab-menu.box-view .WithWidth\(TabMenu\)-root-5{
        margin-bottom:10px;
        padding:0 6px;
    }
    .MuiDialog-paperWidthLg.MuiDialog-paperScrollBody{
        max-width: calc(100% - 32px)!important;
        border-radius:6px!important;
        width:100%;
    }
    .dialog-container .MuiDialog-paper{
        margin:16px;
    }
    .filter-menu-header h3,
    .filter-container label,
    .filter-container .filter-button{
        font-weight:normal!important;
    }
    .filter-menu-header h3{
        font-size:12px;
    }
    .filter-menu .MuiPopover-paper{
        padding:10px 10px 16px 10px!important;
        width:214px;
        top:117px!important;
        left:105px!important;
    }
    .filter-container{
        width:100%;
    }
    .filter-container .filter-button{
        width:80px;
        height:36px;
        font-size:12px;
    }
    .filter-menu-buttons{
        justify-content:center;
    }
    .filter-menu .MuiChip-label{
        font-size:12px;
    }
    #menu-sortedBy .MuiMenuItem-root{
        font-size:12px!important;
        min-height:unset!important;
    }
    .filter-select-box, .filter-multiple-select-box{
        margin-top:10px!important;
        margin-bottom:16px!important;
    }
}

@media(min-width:768px) and (max-width:1024px){
    .responsive-tab-menu{
        display:none!important;
    }
}
