
#sidebar {
    padding: 26px 0px;
    text-align: center;
    position: relative;
    z-index: 3;
    transition: 300ms ease;
}

#sidebar .sidebar-collapser {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 115px;
    right: -15px;
    background-color: #ffbe00;
    max-width: 30px;
    min-width: unset;
    z-index: 1;
}

#sidebar .sidebar-collapser .chevron-icon {
    transition: 300ms ease transform;
    transition-delay: 300ms;
}

#sidebar .nav-menu-wrapper {
    text-align: center;
    margin-top: 40px;
}

#sidebar .nav-menu-wrapper .nav-menu-item {
    opacity: 0.5;
    display: block;
    min-width: unset;
    text-transform: unset;
    padding-left: 45px;
    padding-right: 15px;
    width: 100%;
    margin: 0;
    min-height: 40px;
    height: 80px;
    height: -webkit-calc(100vh / 11.5);
    height: calc(100vh / 11.5);
    max-height: 90px;
}

#sidebar .nav-menu-wrapper .nav-menu-item:hover {
    opacity: 1;
}

#sidebar .nav-menu-wrapper .nav-menu-item .button-text {
    opacity: 1;
    transform: translateX(0%);
    transition: 300ms ease;
}

#sidebar .nav-menu-wrapper .nav-menu-item .MuiButton-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

#sidebar .nav-menu-wrapper .nav-menu-item .MuiButton-label svg:nth-of-type(1) {
    flex: 0 0 28px;
    max-width: 28px;
    width: 28px;
}

#sidebar .nav-menu-wrapper .nav-menu-item .button-text {
    margin-left: 16px;
    color: #fff;
    font-size: 16px;
    pointer-events: none;
}

/* Responsive Designs */
@media screen and (min-width: 1025px) {  
    #sidebar.__collapsed {
        flex: 0 0 120px;
        max-width: 120px;
        width: 120px;
    }
    
    #sidebar:not(.__collapsed) {
        flex: 0 0 265px;
        max-width: 265px;
        width: 265px;
    }
  
    #sidebar:not(.__collapsed) .sidebar-collapser .chevron-icon {
        transform: rotate(180deg);
    }

    #sidebar.__collapsed .nav-menu-wrapper .nav-menu-item .button-text {
        opacity: 0;
        transform: translateX(150%);
    }


    #sidebar:not(.__collapsed) .nav-menu-wrapper .nav-menu-item {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    #sidebar {
        position: fixed;
        left: 0;
        top: 64px;
        background-color: #fff;
        background-image: unset!important;
        box-shadow: 5px 20px 10px 0 rgba(0, 0, 0, 0.16);
        overflow: hidden;
        text-align: left;
        padding: 20px 0px 20px 28px;
        flex: 0 0 280px;
        max-width: 280px;
        width: 280px;
        transform: translateX(-300px);
        -webkit-transform: translateX(-300px);
        -moz-transform: translateX(-300px);
        -ms-transform: translateX(-300px);
        -o-transform: translateX(-300px);
        z-index: 999;
        height: -webkit-calc(100% - 64px); /*137=64+40(padding)*/
        height: calc(100% - 64px);
        box-sizing: border-box;
    }

    #sidebar .logo-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding-right: 10px;
    }

    #sidebar .nav-menu-wrapper {
        height: -webkit-calc(100% - 180px);
        height: calc(100% - 180px);
        height: calc(calc(var(--vh, 1vh) * 100) - 290px);
        overflow-y: auto;
        width: 100%;
        width: -webkit-calc(100% + 16px);
        width: calc(100% + 16px);
    }

    #sidebar .nav-menu-wrapper .nav-menu-item {
        opacity: 1;
        padding-top: 22px;
        padding-bottom: 22px;
        height: unset;
        width: -webkit-calc(100% - 34px)!important;
        width: calc(100% - 34px)!important;
        padding-right: 0;
        padding-left: 0;
        border-bottom: solid 1px rgba(63, 82, 109, 0.12);
    }

    #sidebar .nav-menu-wrapper .nav-menu-item .button-text {
        color: #3f526d;
        display: inline-block;
        width: 100%;
        text-align: left;
    }

    #sidebar .nav-menu-wrapper .nav-menu-item .item-right-button {
        font-size: 28px;
        display: block;
        transition: 100ms linear;
        -webkit-transition: 100ms linear;
        -moz-transition: 100ms linear;
        -ms-transition: 100ms linear;
        -o-transition: 100ms linear;
}

    #sidebar .nav-menu-wrapper .nav-menu-item:hover .item-right-button {
        transform: translateX(5px);
        -webkit-transform: translateX(5px);
        -moz-transform: translateX(5px);
        -ms-transform: translateX(5px);
        -o-transform: translateX(5px);
    }

    #sidebar .logout-button {
        border-bottom: solid 1px rgba(63, 82, 109, 0.12);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 22px;
        padding-bottom: 22px;
        flex-wrap: nowrap;
        color: #3f526d;
        text-transform: unset;
        font-size: 16px;
        padding-left: 0;
        padding-right: 0;
        width: -webkit-calc(100% - 18px)!important;
        width: calc(100% - 18px)!important;
        margin-top: 20px;
    }
    
    #sidebar .logout-button .MuiButton-label svg:nth-of-type(1) {
        flex: 0 0 28px;
        max-width: 28px;
        width: 28px;
        margin-right: 16px;
    }    
}
