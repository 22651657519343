.add-question-box-wrapper{
    padding:11px;
    width:100%;
}

.add-question-box-title{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    white-space: nowrap;
}

.add-question-box-filters{
    display:flex;
    align-items:center;
    gap:10px;
    margin-bottom:17px;
}

.add-question-select-box{
    width:200px;
    font-size: 14px!important;
    font-weight: 600!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

._add-question-select-box{
    font-size: 14px!important;
    font-weight: 600!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.add-question-select-box fieldset{
    border:none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius:8px!important;
}

.add-question-select-box .MuiSelect-select{
    border-radius:8px!important;
    padding:15px 20px;
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
}

.add-question-select-box .MuiInputLabel-outlined{
    transform: translate(15px, 15px) scale(1)!important;
}

.add-question-select-box .MuiInputLabel-outlined.MuiFormLabel-filled{
    display:none;
}

.add-question-box-title-input,
.add-question-box-question-input{
    padding: 10px 20px!important;
    border-radius: 6px;
    background-color: #f9f8fd;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#3f526d;
    width:100%;
}

.add-question-box-question-input{
    margin-bottom:17px;
}

.add-question-box-footer{
    display:flex;
    justify-content:space-between;
}

.add-question-box-footer .add-question-box-buttons{
    display:flex;
    gap:14px;
}

.add-question-box-footer .cancel-button,
.add-question-box-footer .add-button{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform:capitalize!important;
    box-shadow:none;
    width:84px;
    height:38px;
    border-radius:4px!important;
}

.add-question-box-footer .cancel-button{
    background-color: #f6f6f6;
    color:#3f526d;
}

.add-question-box-wrapper .target-group-selectbox {
    background-color: rgba(0,0,0,0.05)!important;
    height:38px;
    overflow:hidden;
}

@media(max-width:1024px){
    .add-question-box-footer .cancel-button, .add-question-box-footer .add-button{
        width:120px;
        font-weight:500;
    }
    .add-question-select-box .MuiInputLabel-outlined{
        transform: translate(15px, 13px) scale(1)!important;
        font-size:12px;
    }
    .add-question-select-box .MuiOutlinedInput-root{
        height:38px;
    }
    ._add-question-select-box{
        font-weight:500!important;
    }
    .add-question-box-title-input input, .add-question-box-question-input input{
        font-size:14px;
        font-weight:500;
        padding:0.68px 0;
    }
    .add-question-box-title{
        font-weight:500;
    }
    #menu-topic li{
        font-size:14px;
        padding-left:10px;
    }
    #menu-topic li:hover{
        font-weight:500;
    }
}

@media(max-width:768px){
    .add-question-box-filters{
        flex-wrap:wrap;
        margin-bottom:0;
        gap:0;
    }
    .add-question-box-title{
        flex:0 0 35%;
        margin-bottom:10px;
    }
    .add-question-select-box{
        flex:0 0 65%;
        margin-left:20px;
        margin-bottom:10px!important;
    }
    .add-question-box-title-input{
        flex:0 0 100%;
        margin-bottom:10px;
        height:38px;
    }
    .add-question-box-title-input input,
    .add-question-box-question-input textarea{
        font-size:12px;
        font-weight:normal;
    }
    .add-question-box-question-input textarea{
        line-height:1.83;
    }
    .add-question-select-box .MuiOutlinedInput-root{
        font-size:12px!important;
    }
    .add-question-box-footer .cancel-button, .add-question-box-footer .add-button{
        font-size:10px;
        height:28px;
        width:60px;
    }
    .add-question-box-footer #target-group-wrapper .target-group-selectbox > .MuiSelect-select.MuiSelect-filled.MuiSelect-filled{
        padding:5px 18px 5px 10px;
    }
    .add-question-box-footer #target-group-wrapper .target-group-selectbox .selectbox-wrapper .selectbox-icon{
        margin-right:8px;
    }
    .add-question-box-wrapper .target-group-selectbox{
        height:28px;
    }
    #menu-topic li{
        font-size:12px;
    }
    .add-question-box-wrapper{
        padding: 10px 8px 5px 8px;
    }
}
