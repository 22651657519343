.send-in-chat-body{
    max-width:640px;
}

.send-in-chat-header,.send-in-chat-recent{
    padding:0 26px 21px 26px;
}

.send-in-chat-title{
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.send-in-chat-body .post-information{
    background-color:#f9f8fd;
    border-radius:8px;
}

.send-in-chat-post-box{
    display:flex;
    align-items:center;
    margin-top:20px;
}

.send-in-chat-post-box img{
    width:42px;
    height:42px;
}

.send-in-chat-post-box input{
    padding:10px 16px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    opacity: 0.5;
    border:none;
    outline:none;
    width:100%;
}

.send-in-chat-divider{
    height: 1px;
    width:100%;
    background-color: rgba(63, 82, 109, 0.28);
}

.search-bar{
    border-radius: 22px;
    border-width: 1px;
    border-style: solid;
    padding-left:20px;
    padding-right:8px;
    display:flex;
    align-items:center;
    /*margin:19px 26px 0 26px;*/
    margin-top:19px;
    height: 44px;
}

.search-input{
    border:none;
    outline:none;
    height:100%;
    width:100%;
    padding-top:12px;
    padding-bottom:12px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#3f526d;
    opacity:0.4;
}

.search-button .search-icon{
    font-size: 27px;
}

.recent-title{
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.recent-activity-item{
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:18px;
}

.recent-information{
    display:flex;
    align-items:center;
}

.recent-information h3{
    margin-left:14px;
}

.send-recent-button{
    height:38px;
    text-transform:capitalize!important;
    padding:19px 33px!important;
    box-shadow:none!important;
    font-size: 14px!important;
    font-weight: 600!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    opacity: 0.7;
}

.send-recent-button:hover {
    opacity: 1;
}

@media(max-width:1024px){
    .send-in-chat-title{
        font-size:14px;
        font-weight:500;
        margin-top:0;
        margin-bottom:27px;
    }
    .send-in-chat-body .single-post-container{
        padding:24px 30px 20px 20px;
    }
    .send-in-chat-body .post-image{
        width:34px;
        height:34px;
    }
    .send-in-chat-body .post-description p{
        line-height:1.86;
        margin-bottom:0;
    }
    .send-in-chat-post-box input{
        font-size:14px;
    }
    .send-in-chat-post-box img{
        width:34px;
        height:34px;
    }
    .send-in-chat-post-box{
        margin:20px 20px 10px 20px;
    }
    .send-in-chat-recent .search-input{
        font-size:12px;
    }
    .send-in-chat-recent .recent-title{
        margin-top:24px;
        margin-bottom:27px;
        margin-left:0;
        font-size:14px;
        font-weight:500;
    }
    .send-in-chat-recent .avatar-field{
        width:44px!important;
        height:44px!important;
    }
    .send-recent-button{
        font-weight:500;
    }
    .recent-activity-item .recent-title{
        margin:0;
    }
}

@media(max-width:768px){
    .send-in-chat-title{
        margin-bottom:9px;
    }
    .send-in-chat-header, .send-in-chat-recent{
        padding:0 12px;
    }
    .send-in-chat-body .single-post-container{
        padding:8px 10px;
    }
    .send-in-chat-body .post-image{
        width:26px;
        height:26px;
    }
    .send-in-chat-body .send-in-chat-header h4{
        font-size:10px;
    }
    .send-in-chat-body .send-in-chat-header .post-description{
        margin-top:12px;
    }
    .send-in-chat-post-box{
        margin:10px 0;
    }
    .send-in-chat-post-box img{
        width:26px;
        height:26px;
    }
    .send-in-chat-post-box input{
        font-size: 10px;
    }
    .send-in-chat-header{
        padding:0 12px;
    }
    .send-in-chat-recent{
        padding:0 22px;
    }
    .send-in-chat-recent .search-input{
        padding-top:9px;
        padding-bottom:9px;
        font-size:10px;
        opacity:0.6;
    }
    .send-in-chat-recent .search-button .search-icon{
        font-size:18px;
    }
    .send-in-chat-recent .recent-title{
        margin-top:14px;
        margin-bottom:10px;
        font-size:12px;
    }
    .send-in-chat-recent .search-bar{
        height:32px;
    }
    .send-in-chat-recent .avatar-field{
        width:37px!important;
        height:37px!important;
        margin-right:10px;
    }
    .send-recent-button{
        font-size:12px!important;
        font-weight:500!important;
        width:84px;
        height:32px;
        padding:7px 28px;
    }
    .recent-activity-item{
        margin-bottom: 15px;
    }
}
