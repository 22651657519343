.add-answer-dialog{
    padding:4px 24px 24px 24px;
    width:600px;
}

.add-answer-dialog-header{
    display:flex;
    align-items:center;
}

.add-answer-dialog-header img{
    width:34px;
    height:34px;
    border-radius:50%;
}

.add-answer-dialog-header h3{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    margin-left:12px;
}

.add-answer-dialog-body p{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #3f526d;
    margin:20px 0 14px 0;
}

.add-answer-dialog textarea{
    border:none;
    resize:none;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(63, 82, 109, 0.36);
    width:100%;
}

.add-answer-dialog textarea::placeholder {
    color:rgba(63, 82, 109, 0.36);
}

.add-answer-dialog textarea:focus{
    outline:none;
}

.add-answer-actions{
    display:flex;
    justify-content:flex-end;
    align-items:center;
    gap:8px;
    position:relative;
}

.add-answer-actions img{
    width:20px;
    height:20px;
}

.add-answer-dialog .vertical-line{
    height:22.5px;
    width:1px;
    background-color:rgba(63, 82, 109, 0.12);
}

.add-answer-button{
    font-size: 16px!important;
    font-weight: 600!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    text-transform:capitalize!important;
    width:100px;
}

.add-answer-dialog .image-list.__one-item .image-item{
    width: 100%;
    height: 256px;
}
.add-answer-dialog .image-list .image-item {
    padding: 6px;
    position: relative;
    margin-bottom:20px;
}
.add-answer-dialog .image-list .image-item img{
    width: 100%;
    height: 100%;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 8px;
}
.add-answer-dialog .image-list.__one-item .image-item img{
    object-fit: contain!important;
}

.add-answer-dialog .file-list-item{
    margin-bottom:20px;
}

@media(max-width:1024px){
    .add-answer-dialog{
        width:88vw;
    }
    .add-answer-dialog-header h3{
        font-size:12px;
        font-weight:500;
    }
    .add-answer-dialog-header img{
        width:30px;
        height:30px;
    }
    .add-answer-dialog .file-list-item .file-name{
        font-size:14px;
        font-weight:500;
    }
    .add-answer-dialog .file-list-item .file-upload-time{
        font-size:12px;
    }
}

@media(max-width:768px){
    .add-answer-dialog{
        padding:0 20px 10px 10px;
        width:unset;
    }
    .add-answer-dialog-header h3{
        font-size:10px;
    }
    .add-answer-dialog-header img{
        width:26px;
        height:26px;
    }
    .add-answer-dialog-body p,
    .add-answer-dialog textarea{
        font-size:12px;
        font-weight:500;
    }
    .add-answer-dialog-body p{
        margin:14px 0;
    }
    .add-answer-actions img{
        width:18px;
        height:18px;
    }
    .add-answer-actions{
        gap:0;
    }
    .add-answer-button{
        margin-left:8px;
        font-size:12px!important;
        font-weight:500!important;
        width:84px;
        height:32px;
    }
    .add-answer-dialog .file-list-item .file-name{
        font-size:12px;
        margin-left:8px;
    }
    .add-answer-dialog .file-list-item .file-upload-time{
        font-size:10px;
    }
    .add-answer-dialog .file-list-item .file-icon{
        width:26px;
        height:26px;
    }
}
