
.top-students-menu-container{
    padding: 30px 5px 40px 40px;
    border-top-left-radius: 40px;
    flex:0 0 250px;
    max-width:250px;
    width: 250px;
    margin-right:-26px;
    margin-left:40px;
    z-index: 10;
    overflow:hidden;
}

.top-students-menu-container h3,
.top-students-card h4,
.top-students-card span{
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin:0;
}

.top-students-menu-container h3{
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin-bottom:36px;
}

.top-students-card{
    display:flex;
    align-items:center;
    gap:15px;
    margin-bottom:28px;
}

.top-students-card img{
    width:46px;
    height:46px;
    border-radius:50%;
    border: solid 2px #fff;
}

.top-students-card h4{
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.top-students-card span{
    font-size: 12px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.76);
}

.top-students-menu-container .no-data-section {
    font-size: 16px;
    color: white;
    opacity: 0.6;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 26px;
    margin-top: -36px;
    height: 100%;
}

@media screen and (max-width: 1024px) {
    .top-students-menu-container.__mobile-view {
        position: fixed;
        right: 18px;
        bottom: 60px;
        width: 225px;
        max-width: 225px;
        flex: 0 0 225px;
        max-height: 80vh;
        max-height: calc(var(--vh, 1vh) * 100);
        border-radius: 40px;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        -ms-border-radius: 40px;
        -o-border-radius: 40px;
        box-sizing: border-box;
        margin:0;
        padding:0;
        overflow: hidden;
    }

    .top-students-menu-container.__mobile-view:not(.__collapsed) {
        border-radius: 14px;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
}

    .top-students-menu-container.__mobile-view .top-students-list {
        overflow-y: auto;
        max-height: -webkit-calc(100% - 60px);
        max-height: calc(100% - 60px);
        width: 100%;
        width: -webkit-calc(100% + 16px);
        width: calc(100% + 16px);
        margin-top: 10px;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        -webkit-transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        -moz-transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        -ms-transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        -o-transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    }

    .top-students-menu-container.__mobile-view.__collapsed {
        width: 160px;
        max-width: 160px;
        flex: 0 0 160px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        padding:0;
        height: 40px;
    }

    .top-students-menu-container.__mobile-view.__collapsed .top-students-list {
        max-height: 0;
    }

    .top-students-menu-container.__mobile-view:not(.__collapsed) .top-students-list {
        max-height: 80vh;
        max-height: calc(80vh - 60px);
        max-height: calc(calc(var(--vh, 1vh) * 80) - 60px);
    }

    .top-students-menu-container.__mobile-view .top-students-list .top-students-card {
        padding: 0 16px;
    }

    .top-students-menu-container.__mobile-view .title-button {
        text-transform: unset;
        letter-spacing: 0;
        font-size: 12px;
        color:#fff;
        padding-top: 15px;
    }

    .top-students-menu-container.__mobile-view .title-button .title-button-icon {
        font-size: 24px;
        margin-right: 14px;
    }

    .top-students-menu-container.__mobile-view.__collapsed .title-button {
        padding-top: 0;
        height: 100%;
    }

    .top-students-menu-container.__mobile-view:not(.__collapsed) .title-button {
        font-size: 14px;
        justify-content: flex-start;
    }

    .top-students-menu-container.__mobile-view:not(.__collapsed) .title-button .title-button-icon {
        margin-right: 18px;
        font-size: 30px;
    }
}

@media screen and (max-width: 767px) {
    .top-students-menu-container.__mobile-view {
        bottom: 33px;
    }
}