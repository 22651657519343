.bottom-menu-container{
    box-shadow: 0 -4px 6px 0 rgba(0,0,0,0.1);
    background-color: #f9f8fd;
    height:80px;
    width:100%;
    position:fixed;
    bottom:0;
    z-index:2;
    display:flex;
    align-items:center;
    justify-content:center;
    gap:70px;
    padding:10px;
}

.bottom-menu-button{
    font-size:14px!important;
    font-weight:500!important;
    text-transform:capitalize!important;
    padding:20px 10px;
    line-height:inherit!important;
    color:#3f526d;
}

.bottom-menu-button img{
    width:24px;
    height:24px;
}

.bottom-menu-button .MuiButton-label{
    display:flex;
    flex-direction:column;
    align-items:center;
}

.bottom-menu-button .MuiButton-startIcon{
    margin-bottom:6px;
    margin-right:0;
}

.menu-plus-button{
    height:58px;
    width:58px;
    border-radius:50%;
    margin-top:-70px!important;
}

.menu-plus-button img{
    width:26px;
    height:26px;
}

.plus-list-container{
    padding:4px 37px!important;
    display:flex;
    align-items:center;
    gap:16px;
}

.plus-list .MuiPopover-paper{
    box-shadow: 0 0 4px 0 rgba(127, 108, 252, 0.5);
    border-radius:30px;
}

.plus-list .MuiMenu-list{
    padding:0;
}

.plus-list img{
    height:20px;
    width:20px;
}

.plus-list li{
    font-size:12px;
    display:flex;
    flex-direction:column;
    align-items:center;
    color:white;
    text-transform:capitalize!important;
}

.more-list img{
    width:26px;
    height:26px;
    margin-right:12px;
}

.more-list svg{
    margin-right:12px;
}

.more-list li{
    font-size:14px;
    padding:20px;
    color:#3f526d;
    text-transform:capitalize!important;
}

.more-list .MuiPopover-paper{
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    width:180px;
    top:unset!important;
    bottom:100px;
}

@media(min-width:1024px){
    .bottom-menu-container{
        display:none!important;
    }
}

@media(max-width:768px){
    .bottom-menu-container{
        display:none!important;
    }
}

