.create-post-box{
    gap:14px;
    margin-top:20px!important;
    display:flex;
    align-items:center;
}

.create-post-box img{
    width:34px;
    height:34px;
}

.create-post-box form{
    display:flex;
    width:100%;
}

.create-post-box input{
    flex:1 1 0;
    width:100%;
    font-weight:600;
    padding: 13px 90px 13px 20px;
    position:relative;
    background-color:#f9f8fd;
    border-radius: 6px;
    border:none;
    min-height:18px;
}

.create-post-box input::placeholder {
    color:rgba(63, 82, 109, 0.2);
}

.create-post-box input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:rgba(63, 82, 109, 0.2);
}

.create-post-box input::-ms-input-placeholder { /* Microsoft Edge */
    color:rgba(63, 82, 109, 0.2);
}

.create-post-box-field{
    display:flex;
    align-items:center;
    width:100%;
    position:relative;
}

.create-post-box-field form{
    margin:0
}

.create-post-box-buttons{
    position:absolute;
    right:22px;
    display:flex;
    align-items:center;
    gap:8px;
}

.create-post-box-buttons img{
    height:18px;
}

.create-post-box-button{
    height:20px;
    width:20px;
}

@media(max-width:1024px){
    .create-post-box input{
        font-size:12px;
        font-weight:500;
    }
    .create-post-box img{
        width:30px;
        height:30px;
    }
    .create-post-box .create-post-box-button img{
        width:18px;
        height:18px;
    }
}

@media(max-width:768px){
    .create-post-box{
        margin-top:10px!important;
        gap:10px;
    }
    .create-post-box input{
        padding:10px 90px 10px 10px;
        font-size:10px;
    }
    .create-post-box .create-post-box-button img{
        width:16px;
        height:16px;
    }
    .create-post-box-buttons{
        right:15px;
    }
}
