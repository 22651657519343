.comment-box{
    display:inline-flex;
    margin-bottom:10px;
    width:100%;
}

.comment-box img{
    width:26px;
    height:26px;
    border-radius:50%;
}

.comment-box h5{
    font-size: 10px;
    line-height: normal;
    color: #3f526d;
    font-weight: 600;
    margin: 0 0 2px 0;
}
