.dialog-container .MuiDialogContent-root{
    padding:0
}

.dialog-container .MuiPaper-root{
    border-radius:12px!important;
}

.close-dialog-button{
    position:absolute!important;
    top:0;
    right:0;
}

.download-dialog-button{
    position:absolute!important;
    top:30px;
    right:30px;
    border-radius:6px!important;
    border: solid 0.5px rgba(63, 82, 109, 0.24)!important;
    width:44px;
    height:44px;
}

.dialog-container .MuiBackdrop-root{
    background-color:rgba(0, 0, 0, 0.25);
}

.download-dialog-button img{
    width:14px;
}
