
/* Textarea */

#create-content-widget {
    margin-bottom: 24px;
}

#create-content-widget .widget-title{
    font-size: 14px;
    font-weight: 600;
    color: #3f526d;
    margin-bottom: 14px;
    }

#create-content-widget .widget-content .textarea {
}

#create-content-widget .widget-content .textarea textarea {
    font-size: 14px;
    font-weight: 600;
    color: #3f526d;
}

#create-content-widget .widget-content .textarea textarea::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: rgba(63, 82, 109, 0.68);
}

#create-content-widget .widget-content .textarea .mui-textarea-wrapper{
    padding: 22px 23px;
    min-height: 86px;
    border-radius: 8px;
    margin-bottom: 40px;
    background-color: #f9f8fd;
}

#create-content-widget .widget-content#add-to-badge .textarea .mui-textarea-wrapper{
    min-height: 59px;
    margin-bottom: 30px;
}


#create-content-widget .widget-content .textarea .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 0;
}

#create-content-widget .widget-content .textarea .avatar {
    width: 42px;
    height: 42px;
    margin-right: 15px;
}


/* CREATE CONTENT DIALOG */
.create-content-dialog-wrapper {
    width: 640px;
    padding: 20px 26px;
    padding-top: 0;
    max-width: 100%;
}

.create-content-dialog-wrapper .dialog-title {
    font-size: 18px;
    color: #3f526d;
    font-weight: 600;
}

.create-content-dialog-wrapper .dialog-content {
}

.create-content-dialog-wrapper .dialog-content .personal-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: 26px;
}

.create-content-dialog-wrapper .dialog-content .personal-info .avatar {
    margin-right: 14px;
}

.create-content-dialog-wrapper .dialog-content .personal-info strong {
    font-size: 16px;
    font-weight: 600;
    color: #3f526d;
}

.create-content-dialog-wrapper .dialog-content .textarea textarea {
    font-size: 16px;
    color: #3f526d;
}

.create-content-dialog-wrapper .dialog-content .textarea textarea::placeholder {
    color: rgba(63, 82, 109, 0.5);
}

.create-content-dialog-wrapper .dialog-content .post-content.__highlighted{
    border-width: 0.5px;
    border-style: solid;
    background-color: #f9f8fd;
    padding: 7px;
    border-radius: 8px;
    position: relative;
    margin-top: 40px;
}

.create-content-dialog-wrapper .dialog-content .post-content .description{
    color: #3f526d;
    line-height: 26px;
    font-size: 16px;
    letter-spacing: 0;
    padding: 6px;
}

.create-content-dialog-wrapper .dialog-content .post-content.__has-images .description{
    margin-top: 8px;
}

.create-content-dialog-wrapper .dialog-content .post-content.__has-files .description{
    margin-bottom: 8px;
}

.create-content-dialog-wrapper .dialog-content .post-content:not(.__has-description) .description{
    padding: 0;
}

.create-content-dialog-wrapper .dialog-content .image-list.__zero-item {
    padding: 0;
    border: 0;
}

.create-content-dialog-wrapper .dialog-content .image-list .image-item {
    padding: 6px;
    position: relative;
}

.create-content-dialog-wrapper .dialog-content .image-list.__one-item .image-item {
    width: 100%;
    height: 256px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__one-item .image-item img{
    object-fit: contain!important;
}

.create-content-dialog-wrapper .dialog-content .image-list.__two-items {
    display: grid;
    grid-template-columns: 50% 50%;
}

.create-content-dialog-wrapper .dialog-content .image-list.__two-items .image-item {
    width: 100%;
    height: 256px;
}


.create-content-dialog-wrapper .dialog-content .image-list.__three-items {
    display: grid;
    grid-template-columns: 50% 50%;
}


.create-content-dialog-wrapper .dialog-content .image-list.__three-items .image-item:nth-child(1) {
    grid-row: span 2;
    height: 256px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__three-items .image-item:nth-child(2) {
    grid-row: span 1;
    height: 128px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__three-items .image-item:nth-child(3) {
    grid-row: span 1;
    height: 128px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__higher-than-three-items .images-count {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    text-align: center;
    font-size: 20px;
    letter-spacing: 0;
    position: absolute;
    left:0;
    top:0;
    background-color: rgba(0,0,0,0.35);
    width: 100%;
    width: calc(100% - 12px);
    height: 100%;
    height: calc(100% - 12px);
    margin-left: 6px;
    margin-top: 6px;
    user-select: none;
    pointer-events: none;
    border-radius: 8px;
    overflow: hidden;
    color: #fff;
}

.create-content-dialog-wrapper .dialog-content .image-list .image-item img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
    border-radius: 8px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__two-items .image-item {

}

.create-content-dialog-wrapper .dialog-content .closeButton {
    position: absolute;
    right: 5px;
    top: 5px;
    min-width: unset;
}

.create-content-dialog-wrapper .dialog-content .closeButton svg {
    color: #fff;
}

.create-content-dialog-wrapper #create-content-footer-section-wrapper {
    margin-top: 20px;
}

@media(max-width:1024px){
    #create-content-widget{
        padding:14px;
    }
    #create-content-widget .widget-content .textarea textarea,
    #create-content-widget .widget-title{
        font-size:12px;
        font-weight:500;
    }
    #create-content-widget .widget-title{
        margin-bottom:20px;
    }
    #create-content-widget .widget-content .textarea .avatar{
        width:34px;
        height:34px;
    }
    #create-content-widget .generic-card{
        padding:10px 14px;
    }
    #create-content-widget .widget-content .textarea .mui-textarea-wrapper{
        padding:12px;
        min-height:58px;
        margin-bottom:30px;
    }
    .create-content-dialog-wrapper .dialog-title{
        font-size:14px;
        font-weight:500;
    }
    .create-content-dialog-wrapper .dialog-content .personal-info strong{
        font-size:14px;
        font-weight:500;
    }
    .create-content-dialog-wrapper .dialog-content .personal-info .avatar{
        width:34px;
        height:34px;
        margin-right:10px;
    }
    .create-content-dialog-wrapper .dialog-content .personal-info{
        margin-top:18px;
        margin-bottom:20px;
    }
    .create-content-dialog-wrapper .dialog-content .textarea textarea{
        font-size:14px;
    }
    .create-content-dialog-wrapper .dialog-content .post-content.__highlighted{
        margin:40px 50px;
    }
    .create-content-dialog-wrapper #create-content-footer-section-wrapper{
        margin:20px 50px 0 50px;
    }
    .create-content-dialog-wrapper .dialog-content .post-content .description{
        font-size:14px;
        line-height:1.86;
    }
    .share-to-feed-content .post-content.__highlighted{
        margin:40px 0;
    }
}

@media(max-width:768px){
    #create-content-widget{
        padding:10px;
        margin-bottom:10px;
    }
    #create-content-widget .widget-title{
        margin-bottom:10px;
    }
    .create-content-dialog-wrapper{
        padding:3px 20px 20px 20px;
    }
    #create-content-widget .widget-content .textarea .mui-textarea-wrapper{
        padding:8px 10px;
        margin-bottom:17px;
        min-height:50px;
    }
    #create-content-widget .widget-content .textarea .avatar{
        width:28px;
        height:28px;
        margin-right:0;
    }
    #create-content-widget .widget-content .textarea textarea,
    #create-content-widget .widget-content .textarea textarea::placeholder,
    #create-content-widget .widget-title,
    #target-group-wrapper .target-group-selectbox .selectbox-wrapper,
    #create-content-footer-section-wrapper .right-side .action-button,
    #create-content-footer-section-wrapper .left-side .section-item{
        font-size:10px;
    }

    #create-content-widget .widget-content .textarea textarea{
        opacity:0.68;
    }
    #create-content-footer-section-wrapper .left-side .section-item img{
        width:16px;
        height:16px;
        margin-right:0;
    }
    #create-content-footer-section-wrapper .right-side .action-button{
        height:28px;
        width:60px;
        margin-left:12px;
    }
    .share-to-feed-content~#create-content-footer-section-wrapper .right-side .action-button{
        margin-left:6px;
    }
    #target-group-wrapper .target-group-selectbox.__small > .MuiSelect-select{
        padding:6px 20px 5px 10px;
    }
    #create-content-footer-section-wrapper{
        flex-wrap:nowrap;
    }
    .create-content-dialog-wrapper .dialog-title{
        font-size:12px;
    }
    .create-content-dialog-wrapper .dialog-content .personal-info{
        margin-bottom:12px;
    }
    .create-content-dialog-wrapper.create-content-timeline .dialog-content .personal-info{
        margin-top:8px;
        margin-bottom:18px;
    }
    .create-content-dialog-wrapper .dialog-content .personal-info .avatar{
        width:26px;
        height:26px;
    }
    .create-content-dialog-wrapper .dialog-content .personal-info strong{
        font-size:10px;
    }
    .create-content-dialog-wrapper .dialog-content .textarea textarea{
        font-size:12px;
        opacity:0.5;
    }
    .create-content-dialog-wrapper .dialog-content .post-content.__highlighted{
        margin:16px 0 20px 0;
    }
    .create-content-dialog-wrapper .dialog-content .post-content .description{
        font-size:12px;
        line-height:1.5;
    }
    .create-content-dialog-wrapper #create-content-footer-section-wrapper{
        margin:20px 0 0 0;
    }
    .share-to-feed-content  .image-list.__higher-than-three-items .image-item,
    .create-content-timeline  .image-list.__higher-than-three-items .image-item{
        height:150px!important;
        grid-row:span 1!important;
        padding:3px;
    }
    .create-content-dialog-wrapper .dialog-content .share-to-feed-content .image-list.__higher-than-three-items .images-count,
    .create-content-dialog-wrapper.create-content-timeline .dialog-content .share-to-feed-content .image-list.__higher-than-three-items .images-count{
        width:calc(100% - 6px);
        height:calc(100% - 6px);
        margin-left:3px;
        margin-top:3px;
    }
    .create-content-dialog-wrapper .dialog-content .closeButton {
        right: 3px;
        top: 3px;
        padding:3px 11px
    }

    .create-content-dialog-wrapper .dialog-content .closeButton svg {
        font-size:1.3rem;
    }
}
