.notification-menu-list .MuiPopover-paper{
    border-radius: 6px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    width:480px;
    max-height:640px;
}

.notification-menu-list-header{
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:20px 30px;
}

.notification-menu-list-header-separator{
    border: solid 1px rgba(63, 82, 109, 0.28);
    margin:0;
    border-bottom-width:0;
}

.notification-menu-list-header h3{
    font-size:14px;
    font-weight:500;
    margin:0;
}

.notification-menu-list-header img{
    width:18px;
    height:18px;
}

.notification-menu-list-body{
    padding:9px 30px 0 30px;
}

.notification-menu-list-item{
    display:flex;
    align-items:center;
}

.notification-menu-list-item-info span{
    font-size:14px;
    margin-bottom:2px;
    color: #3f526d;
}

.notification-menu-list-item-date{
    font-size:12px;
}

.notification-menu-list-username{
    font-weight:500;
}

.notification-menu-list-item img{
    margin-right:14px;
    width:38px;
    height:38px;
    border-radius:50%;
}

.notification-menu-list-body hr{
    border: solid 0.5px rgba(63, 82, 109, 0.28);
    margin:13px 0;
    border-bottom-width:0;
}

.notification-menu-list .view-all-button{
    height:38px;
    width:160px;
    margin:0 auto;
    text-transform:capitalize!important;
    font-size:12px!important;
    font-weight:500!important;
}

.notification-menu-list-date{
    font-size:12px;
    font-weight:500;
    margin-bottom:18px;
}

.notification-view-all-button{
    width:100%;
    display:flex;
    justify-content:center;
}

@media(max-width:768px){
    .notification-menu-list-header{
        padding:20px;
    }
    .notification-menu-list-header h3{
        font-size:12px;
    }
    .notification-menu-list-date{
        margin-bottom:13px;
    }
    .notification-menu-list-body{
        padding:15px 20px 0 20px;
    }
    .notification-menu-list-item img{
        width:28px;
        height:28px;
        margin-right:6px;
    }
    .notification-menu-list-item-info span{
        font-size:12px;
    }
    .notification-menu-list-item-date{
        font-size:10px;
    }
    .notification-menu-list-body hr{
        margin:8px 0 11px 0;
    }
    .notification-menu-list .view-all-button{
        margin:10px 0;
    }
    .notification-menu-list .close-dialog-button{
        position:static!important;
        padding:0;
    }
    .notification-menu-list .MuiPopover-paper{
        top:70px!important;
    }
}
